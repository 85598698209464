import React, { useState } from "react";
import { API } from "aws-amplify";
import { getInputContext } from "../utils/api-utils";
import { CASE_MANAGEMENT_SERVICE_API } from "../constants/api-paths";
import {PENDING_CASES, STRYFE_CLIENTID, STRYFE_USE_CASE_NAME} from "src/components/StryfeAPI/Constants";

export async function getStryfeCasesSummary(startDate, endDate) {
    let stryfeRequest = {
        clientId: STRYFE_CLIENTID,
        useCaseName: STRYFE_USE_CASE_NAME,
        startDate:startDate,
        endDate:endDate,
        team:"CAC"
    }
    let result = {
        data: {},
    };
    const inputContext = await getInputContext();
    inputContext.body.requestType = "GetAggregateData";
    inputContext.body.requestString = JSON.stringify(stryfeRequest);
    const resp = await API.post(
        CASE_MANAGEMENT_SERVICE_API,
        "/getaggregatedata",
        inputContext
    );
    if (resp.statusCode === "200") {
        const parsedjson = JSON.parse(resp.body);
        result.data = parsedjson.aggregateValues;
        return result;
    } else {
        console.log("No status code" + JSON.stringify(resp));
        throw 'exception occurred';
    }
}
