import React, { useState } from "react";
import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import { BrowserRouter} from "react-router-dom";
import AppConfig from "../AppConfig";
import Router from "./Router";
import "../base.scss";

interface AppProps {}

interface AppState {
  user: string;
  idToken: string;
  authenticationStage: string;
  authenticationError: string;
  errorMessage: string;
  domain: string;
}

function isBlank(string) {
  return /\S/.test(string);
}

function isEmptyString(string) {
  if (!string || !isBlank(string)) {
    return true;
  }
  return false;
}
export class App extends React.Component<AppProps, AppState> {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      idToken: "",
      authenticationStage: "un-authenticated",
      authenticationError: "",
      errorMessage: "",
      domain: "",
    };
  }

  async componentDidMount() {
  // @ts-ignore
    let domain = "beta" == "test" ? "development" : "beta";
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: AppConfig[domain].REGION,
        userPoolId: AppConfig[domain].USER_POOL_ID,
        userPoolWebClientId: AppConfig[domain].APP_CLIENT_ID,
        oauth: AppConfig[domain].OAUTH
      },
      API: AppConfig[domain].API_GATEWAY_ENDPOINTS
    });

    try {
      let currentSession = await Auth.currentSession(); //checks if any user session is present
    } catch (error:any) {
      if (error === "No current user") {
        this.setState({ authenticationStage: "signIn" });
        Auth.federatedSignIn();
      } else {
        this.setState({ authenticationError: error });
      }
    }

    try {
      let userInfo = await Auth.currentAuthenticatedUser();
      let clientLogin =
        userInfo.signInUserSession.idToken.payload.identities[0].userId;
      let idToken = userInfo.signInUserSession.idToken.jwtToken;
      this.setState({
        user: clientLogin,
        idToken: idToken,
        authenticationStage: "authenticated",
        errorMessage: "",
        domain: domain,
      });
    } catch (error:any) {
      this.setState({ authenticationError: error });
    }
  }

  render() {
    if (
      this.state.authenticationStage === "un-authenticated" &&
      !isEmptyString(this.state.authenticationError)
    ) {
      return (
        <div className="App">
          <header className="App-header">
            <h3>
              Some error occured while trying to log in. Please try again.
            </h3>
          </header>
        </div>
      );
    } else if (this.state.authenticationStage === "authenticated") {
      return (
        <BrowserRouter>
         <Router domain={this.state.domain}/>
        </BrowserRouter>
      );
    } else {
      return (
        <div className="App">
          <header className="App-header">
            <h1>Loading..</h1>
          </header>
        </div>
      );
    }
  }
}

export default App;
