import React from "react";
import Alert from '@amzn/awsui-components-react/polaris/alert';

const PageNotFound = () => {
return (
  <Alert type="error" header="Page does not exists!">
    Page does not exists!
  </Alert>
  );
};
export default PageNotFound;

