import React from "react"
import {getInputContext} from "src/utils/api-utils";
import {API} from "aws-amplify";
import {CASE_MANAGEMENT_SERVICE_API} from "src/constants/api-paths";
import {STRYFE_CLIENTID, STRYFE_USE_CASE_NAME} from "src/components/StryfeAPI/Constants";

export async function updateCase(updatedCase,caseId, taskToken) {
    if(updatedCase == null) {
        updatedCase = {};
    }
    updatedCase['taskToken'] = taskToken;
    let stryfeUpdateCaseRequest = {
        clientId: STRYFE_CLIENTID,
        "useCaseName": STRYFE_USE_CASE_NAME,
        "caseId": caseId,
        "attributes": updatedCase
    }
    const inputContext = await getInputContext();
    inputContext.body.requestType = "UpdateCase";
    inputContext.body.requestString = JSON.stringify(stryfeUpdateCaseRequest);
    const resp = await API.post(
        CASE_MANAGEMENT_SERVICE_API,
        "/updatecase",
        inputContext
    );
    if(resp.statusCode !== "200")
    {
        console.log("No status code" + JSON.stringify(resp));
    }
}