import React from "react";
import "./Card.scss"
import logo from "src/components/assets/amazon_taskless_logo.svg";
import "./StryfeHeader.scss"

const StryfeHeader = () => {
    return (
        <div>
            <div className='stryfe-header'>
                <div className='stryfe-header-title'>Senna</div>
                <div className='stryfe-image'><img src={logo}/></div>
            </div>
        </div>
    )
}

export default StryfeHeader;