import {API} from "aws-amplify";
import {getInputContext} from "../utils/api-utils";
import {getFlashBar} from "../utils/common-utils";
import {CASE_MANAGEMENT_SERVICE_API, UPDATE_CASE_STATUS} from "../constants/api-paths";
import {INTERNAL_ERROR_FLASH_BAR_PROPS, NOT_AUTHORIZED_FLASH_BAR_PROPS} from "../constants/common";

let request = {
  "useCaseName": "",
  "caseId" : "",
  "clientId" : "ComplianceDashboard",
  "status" : "",
  "updateMessage" : "",
  "falsePositiveReason": "",
};

export async function updateCaseStatus(useCaseName, caseId, status, updateMessage, falsePositiveReason) {

    getInputContext().then(input => {
        request.useCaseName = useCaseName;
        request.caseId = caseId;
        request.status = status;
        request.updateMessage = updateMessage;
        request.falsePositiveReason = falsePositiveReason;
        input.body.requestType = "UpdateCaseStatus";
        input.body.requestString = JSON.stringify(request);
        console.log("Input for add comment = " + JSON.stringify(request));
        API.post(CASE_MANAGEMENT_SERVICE_API, UPDATE_CASE_STATUS, input).then(resp => {
            console.log(UPDATE_CASE_STATUS + " API was called, response : " + JSON.stringify(resp));
            if (resp.statusCode === "200") {
                return "Success";
            } else{
                console.log("Error in updating status to false-positive for caseId : " + caseId);
            }
        });
    });

}
