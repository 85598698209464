import React from "react";
import {API} from "aws-amplify";
import {getInputContext} from "../utils/api-utils";
import {getFlashBar} from "../utils/common-utils";
import {CASE_MANAGEMENT_SERVICE_API, CHECK_USER_AUTHORIZATION} from "../constants/api-paths";
import {INTERNAL_ERROR_FLASH_BAR_PROPS, NOT_AUTHORIZED_FLASH_BAR_PROPS} from "../constants/common";

let authRequest = {
  "clientId": "ComplianceDashboardWebsite",
  "useCaseName": "Fas5",
  "team":"RAR",
  "userAlias": ""
};

export async function checkUserAuthorization(page, setContent) {

    getInputContext().then(input => {
    console.log("input.body.userAlias = " +input.body.userAlias);
        authRequest.userAlias = input.body.userAlias;
       authRequest.userAlias = authRequest.userAlias.split("_")[1];
        input.body.requestType = "CheckUserAuthorization";
        input.body.requestString = JSON.stringify(authRequest);
        console.log("CheckAuth request = " + JSON.stringify(authRequest));
        API.post(CASE_MANAGEMENT_SERVICE_API, CHECK_USER_AUTHORIZATION, input).then(resp => {
            console.log(CHECK_USER_AUTHORIZATION + " API was called, response : " + JSON.stringify(resp));
            if (resp.statusCode === "200") {
            console.log("status 200");
            const respBody = JSON.parse(resp.body);
                if(respBody.isAuthorized === false){
                console.log(authRequest.userAlias + " not auth");
                    setContent(getFlashBar(NOT_AUTHORIZED_FLASH_BAR_PROPS));
                }
            } else{
                setContent(getFlashBar(INTERNAL_ERROR_FLASH_BAR_PROPS));
            }
        });
    });

}
