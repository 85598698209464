import React, {useEffect, useState} from "react";
import {ColumnLayout, Container, SpaceBetween, StatusIndicator} from "@amzn/awsui-components-react";
import {
    areDatesOfSameMonth,
    isAttributeValueComputationRequired,
    isInvoiceReceivedAfterCutOffDate,
    ValueWithLabel,
    getSennaStatus,
    getSennaStatusTypeIndicator
} from "src/utils/common-utils";
import InvoiceInformation from "src/components/stryfe/InvoiceInformation";
import {PENDING_PARSING_COMPLETION} from "src/components/StryfeAPI/Constants";

function InvoiceDetails(props) {
    const [areDatesOfSameMonthResult, setAreDatesOfSameMonthResult] = useState(false);
    const [isInvoiceReceivedAfterCutOffDateResult, setIsInvoiceReceivedAfterCutOffDateResult] = useState(false);

    useEffect(() => {
        if (props.invoiceDetail.invoiceDate != null && props.invoiceDetail.lfCaseCreationDate != null) {
            setAreDatesOfSameMonthResult(() => {
                return areDatesOfSameMonth(props.invoiceDetail.lfCaseCreationDate, props.invoiceDetail.invoiceDate);
            });
            setIsInvoiceReceivedAfterCutOffDateResult(() => {
                return isInvoiceReceivedAfterCutOffDate(props.invoiceDetail.lfCaseCreationDate, props.invoiceDetail.invoiceDate);
            });
        }
    }, [props.invoiceDetail.invoiceDate, props.invoiceDetail.lfCaseCreationDate]);

    return (

        <div>
            <Container header={<h2>Invoice Details</h2>}>
                <SpaceBetween size="s" direction="vertical">
                    <ColumnLayout columns={4} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Barcode">
                                {(props.invoiceDetail.barcode != null ? props.invoiceDetail.barcode.toString().split(".")[0] : "--")}
                            </ValueWithLabel>
                            <ValueWithLabel label="Received On Time?">
                                {
                                    isAttributeValueComputationRequired(props.caseStatus, [props.invoiceDetail.invoiceDate, props.invoiceDetail.lfCaseCreationDate], [])
                                        ? <StatusIndicator type={areDatesOfSameMonthResult ? 'success' : 'error'}>
                                            {areDatesOfSameMonthResult ? 'Yes' : 'No'}
                                        </StatusIndicator>
                                        : <StatusIndicator type={"in-progress"}/>
                                }
                            </ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Creation Date">
                                {
                                    isAttributeValueComputationRequired(props.caseStatus, [props.invoiceDetail.lfCaseCreationDate], [])
                                        ? props.invoiceDetail.lfCaseCreationDate
                                        : <StatusIndicator type={"in-progress"}/>
                                }
                            </ValueWithLabel>
                            <ValueWithLabel label="Senna Status">
                                {
                                    isAttributeValueComputationRequired(props.caseStatus, [], []) ||
                                        props.caseStatus == PENDING_PARSING_COMPLETION
                                        ? <StatusIndicator
                                            type={getSennaStatusTypeIndicator(props.caseStatus)}>
                                            {getSennaStatus(props.caseStatus)}
                                        </StatusIndicator>
                                        : <StatusIndicator type={"in-progress"}/>
                                }
                            </ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel
                                label="Type of Invoice">
                                {
                                    isAttributeValueComputationRequired(props.caseStatus, [props.invoiceDetail.invoiceType], [])
                                        ? props.invoiceDetail.invoiceType
                                        : <StatusIndicator type={"in-progress"}/>
                                }
                            </ValueWithLabel>
                            <ValueWithLabel label="Addressee">
                                {
                                    isAttributeValueComputationRequired(props.caseStatus, [props.invoiceDetail.addressee], [])
                                        ? props.invoiceDetail.addressee
                                        : <StatusIndicator type={"in-progress"}/>
                                }
                            </ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel label="LF Status">
                                {
                                    isAttributeValueComputationRequired(props.caseStatus, [props.invoiceDetail.lfStatus], [])
                                        ? props.invoiceDetail.lfStatus
                                        : <StatusIndicator type={"in-progress"}/>
                                }
                            </ValueWithLabel>
                            {
                                isAttributeValueComputationRequired(props.caseStatus, [props.invoiceDetail.invoiceDate, props.invoiceDetail.lfCaseCreationDate], [areDatesOfSameMonthResult])
                                    ? <ValueWithLabel label="Received after cut-off date?">
                                        <StatusIndicator
                                            type={isInvoiceReceivedAfterCutOffDateResult ? "warning" : "success"}>
                                            {isInvoiceReceivedAfterCutOffDateResult ? 'Yes' : 'No'}
                                        </StatusIndicator>
                                    </ValueWithLabel>
                                    : null
                            }
                        </SpaceBetween>
                    </ColumnLayout>
                    <InvoiceInformation invoiceDetail={props.invoiceDetail} caseStatus={props.caseStatus}/>
                </SpaceBetween>
            </Container>
        </div>
    )
}

export default InvoiceDetails;