import React from "react"
import {addColumnSortLabels} from "src/common/labels";
import {DEFAULT_COLUMN_WIDTH} from "src/config/table/common/case-table-config";
import {PropertyFilterProperty} from "@amzn/awsui-collection-hooks";

export const STRYFE_TABLE_COLUMN_DEFINITIONS = addColumnSortLabels(
    [
        {
            id: "barcode",
            header: "Barcode",
            cell: e => <div> {(e.barcode!=null ? e.barcode.toString().split(".")[0] : "--")}</div>,
            sortingField: "Barcode",
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "sopNumber",
            header: "SOP Number",
            cell: e => e.sopNumber,
            sortingField: "SOPNumber",
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "invoiceType",
            header: "Invoice Type",
            cell: e => e.invoiceType,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "revisedInssRate",
            header: "INSS Rate(%)",
            cell: e => e.revisedInssRate,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "revisedIssRate",
            header: "ISS Rate(%)",
            cell: e => e.revisedIssRate,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "confidenceScore",
            header: "INOX Classification Score(%)",
            cell: e => e.confidenceScore,
            minWidth: 400
        },
        {
            id: "invoiceNumber",
            header: " Invoice Number",
            cell: e => e.invoiceNumber,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "invoiceSeries",
            header: " Invoice Series",
            cell: e => e.invoiceSeries,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "fiscalDocumentModel",
            header: " Invoice Model",
            cell: e => e.fiscalDocumentModel,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "invoiceDate",
            header: " Invoice Date",
            cell: e => e.invoiceDate,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "validationCode",
            header: " Validation Code",
            cell: e => e.validationCode,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "supplierTaxId",
            header: " Supplier Tax ID",
            cell: e => e.supplierTaxId,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "supplierName",
            header: " Supplier Name",
            cell: e => e.supplierName,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "supplierCity",
            header: " Supplier City",
            cell: e => e.supplierCity,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "AmazonName",
            header: " Amazon Name",
            cell: e => e.amazonName,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "AmazonTaxID",
            header: " Amazon Tax ID",
            cell: e => e.amazonTaxId,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "poNumber",
            header: " PO Number Or GL String",
            cell: e => e.poNumber,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "glRequester",
            header: " GL Requester",
            cell: e => e.glRequester,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "invoiceAmount",
            header: " Invoice Amount",
            cell: e => e.invoiceAmount,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "invoiceCurrency",
            header: " Invoice Currency",
            cell: e => e.invoiceCurrency,
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "serviceCode",
            header: " Service Code",
            cell: e => e.serviceCode,
            minWidth: DEFAULT_COLUMN_WIDTH
        }
    ]
);

export const STRYFE_TABLE_DEFAULT_PREFERENCES = {
    pageSize: 100,
    visibleContent: [
        "barcode",
        "sopNumber",
        "invoiceType",
        "revisedInssRate",
        "revisedIssRate",
        "confidenceScore",
        "invoiceNumber",
        "invoiceSeries",
        "invoiceModel",
        "invoiceDate",
        "validationCode",
        "supplierTaxId",
        "supplierName",
        "supplierCity",
        "amazonName",
        "amazonTaxId",
        "poNumber",
        "glRequester",
        "invoiceAmount",
        "invoiceCurrency",
        "serviceCode",
        "revisedInssRate",
        "revisedIssRate",
        "orgName",
        "lc116ServiceCode",
        "fiscalDocumentModel",
        "companyCode",
        "ofaLocation",
        "cofinsCredit",
        "irrfWithholdingAmount",
        "inssWithholdingAmount",
        "inssPenalties",
        "issCalculationBasis",
        "issWithholdingAmount",
        "issPenalties",
        "cstPIS",
        "cstCOFINS",
        "isPOValid",
        "currency",
        "itemCode"
    ],
    wrapLines: false,
};

export const STRYFE_TABLE_DEFAULT_DUPLICATE_PREFERENCES = {
    pageSize: 100,
    visibleContent: [
        "barcode",
        "originalBarcode"
    ],
    wrapLines: false,
};

export const STRYFE_VISIBLE_CONTENT_FOR_DUPLICATE_OPTIONS = [
    {
        label: "Stryfe Table Option",
        options: [
            {id: "barcode", label: "Barcode"},
            {id: "originalBarcode", label: "Original Barcode"}
        ],
    },
];


export const STRYFE_VISIBLE_CONTENT_OPTIONS = [
    {
        label: "Stryfe Table Option",
        options: [
            {id: "barcode", label: "Barcode"},
            {id: "sopNumber", label: "SOPNumber"},
            {id: "invoiceType", label: "InvoiceType"},
            {id: "confidenceScore", label: "ConfidenceScore"},
            {id: "invoiceNumber", label: " Invoice Number"},
            {id: "invoiceSeries", label: " Invoice Series"},
            {id: "invoiceModel", label: " Invoice Model"},
            {id: "invoiceDate", label: " Invoice Date"},
            {id: "validationCode", label: " Validation Code"},
            {id: "supplierTaxId", label: " Supplier Tax ID"},
            {id: "supplierName", label: " Supplier Name"},
            {id: "supplierCity", label: " Supplier City"},
            {id: "amazonName", label: " Amazon Name"},
            {id: "amazonTaxId", label: " Amazon Tax ID"},
            {id: "poNumber", label: " PO Number Or GL String"},
            {id: "glRequester", label: " GL Requester"},
            {id: "invoiceAmount", label: " Invoice Amount"},
            {id: "invoiceCurrency", label: " Invoice Currency"},
            {id: "serviceCode", label: " Service Code"},
            {id: "revisedInssRate", label: "INSS Rate(%)"},
            {id: "revisedIssRate", label: "ISS Rate(%)"},
            {id: "orgName", label: "Amazon Location"},
            {id: "lc116ServiceCode", label: "LC 116 Service Code"},
            {id: "fiscalDocumentModel", label: "Invoice Model"},
            {id: "companyCode", label: "Company Code"},
            {id: "ofaLocation", label: "OFA Location"},
            {id: "cofinsCredit", label: "PIS/COFINS Credit"},
            {id: "irrfWithholdingAmount", label: "IRRF Withholding"},
            {id: "inssWithholdingAmount", label: "INSS Withholding Amount"},
            {id: "inssPenalties", label: "INSS Penalties"},
            {id: "issCalculationBasis", label: "ISS Calculation Basis"},
            {id: "issWithholdingAmount", label: "ISS withholding amount"},
            {id: "issPenalties", label: "ISS Penalties"},
            {id: "cstPIS", label: "CST PIS"},
            {id: "cstCOFINS", label: "CST COFINS"},
            {id: "isPOValid", label: "PO Validation Status"},
            {id: "currency", label: "Currency"},
            {id: "itemCode", label: "Item Code"}
        ],
    },
];

export const STRYFE_DUPLICATE_TABLE_COLUMN_DEFINITIONS = addColumnSortLabels(
    [
        {
            id: "barcode",
            header: "Barcode",
            cell: e => <div> {(e.barcode!=null ? e.barcode.toString().split(".")[0] : "--")}</div>,
            sortingField: "Barcode",
            minWidth: DEFAULT_COLUMN_WIDTH
        },
        {
            id: "originalBarcode",
            header: "Original Barcode",
            cell: e => e.originalBarcode,
            minWidth: DEFAULT_COLUMN_WIDTH
        }
    ]
);

export const STRYFE_FILTERING_PROPERTIES: PropertyFilterProperty[] = [
    {
        propertyLabel: "Barcode",
        key: "barcode",
        groupValuesLabel: `Barcode values`,
        operators: [":", "!:", "=", "!="],
    },
    {
        propertyLabel: "SOP Number",
        key: "sopNumber",
        groupValuesLabel: `sop number values`,
        operators: [":", "!:", "=", "!="],
    },
    {
        propertyLabel: "Invoice Type",
        key: "nfType",
        groupValuesLabel: `Invoice Type values`,
        operators: [":", "!:", "=", "!="],
    },
    {
        propertyLabel: "Classification Score",
        key: "confidenceScore",
        groupValuesLabel: `Confidence Score values`,
        operators: [":", "!:", "=", "!=", ">", "<"],
    },
    {
        propertyLabel: "INSS Rate",
        key: "revisedInssRate",
        groupValuesLabel: `INSS Rate values`,
        operators: [":", "!:", "=", "!=", ">", "<"],
    },
    {
        propertyLabel: "ISS Rate",
        key: "revisedIssRate",
        groupValuesLabel: `ISS Rate values`,
        operators: [":", "!:", "=", "!=", ">", "<"],
    },
    {
        propertyLabel: "Invoice Model",
        key: "fiscalDocumentModel",
        groupValuesLabel: `Invoice Model values`,
        operators: [":", "!:", "=", "!="],
    },
];

export const STRYFE_DUPLICATE_FILTERING_PROPERTIES: PropertyFilterProperty[] = [
    {
        propertyLabel: "Barcode",
        key: "barcode",
        groupValuesLabel: `Duplicate Barcode values`,
        operators: [":", "!:", "=", "!="],
    },
    {
        propertyLabel: "Original Barcode",
        key: "originalBarcode",
        groupValuesLabel: `Original Barcode values`,
        operators: [":", "!:", "=", "!="],
    },
];