import React from "react";
import "src/components/stryfe/Card.scss";
import {CASES_DATE_RANGE_MAP} from "src/components/StryfeAPI/Constants";

const SLAWidget = (props) => {

    function setInvoicesVisible() {
        props.data.onClick(true);
    }

    function updateStartDate(date: string) {
        props.data.updateStartDate(date);
    }

    function updateEndDate(date: string) {
        props.data.updateEndDate(date);
    }

    function updateCaseStatus(caseStatus: string) {
        props.data.updateCaseStatus(caseStatus);
    }

    return (
        <div className="sla-widget" onClick={setInvoicesVisible}>
            <div onClick={() => {
                    updateStartDate(CASES_DATE_RANGE_MAP.greenCasesDateRange.startDate),
                    updateEndDate(CASES_DATE_RANGE_MAP.greenCasesDateRange.endDate),
                    updateCaseStatus(props.data.caseStatus);
            }}>
                <h4 className="green-600">{props.data.slaWidgetData.greenCasesSummary.heading}: {props.data.slaWidgetData.greenCasesSummary.count}</h4>
            </div>
            <div onClick={() => {
                    updateStartDate(CASES_DATE_RANGE_MAP.yellowCasesDateRange.startDate),
                    updateEndDate(CASES_DATE_RANGE_MAP.yellowCasesDateRange.endDate),
                    updateCaseStatus(props.data.caseStatus);
            }}>
                <h4 className="amazon-orange">{props.data.slaWidgetData.yellowCasesSummary.heading}: {props.data.slaWidgetData.yellowCasesSummary.count}</h4>
            </div>
            <div onClick={() => {
                    updateStartDate(CASES_DATE_RANGE_MAP.redCasesDateRange.startDate),
                    updateEndDate(CASES_DATE_RANGE_MAP.redCasesDateRange.endDate),
                    updateCaseStatus(props.data.caseStatus);
            }}>
                <h4 className="red-600">{props.data.slaWidgetData.redCasesSummary.heading}: {props.data.slaWidgetData.redCasesSummary.count}</h4>
            </div>
        </div>
    )
}
export default SLAWidget;