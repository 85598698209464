import React from "react";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import { Box, Link } from "@amzn/awsui-components-react";
import { appLayoutLabels, externalLinkProps } from "./labels";

export const InfoLink = ({ id, onFollow }) => (
  <Link variant="info" id={id} onFollow={onFollow}>
    Info
  </Link>
);

// a special case of external link, to be used within a link group, where all of them are external
// and we do not repeat the icon
export const ExternalLinkItem = ({ href, text }) => (
  <Link
    href={href}
    ariaLabel={`${text} ${externalLinkProps.externalIconAriaLabel}`}
    target="_blank"
  >
    {text}
  </Link>
);

export function CustomAppLayout(props) {
  return (
    <AppLayout
      {...props}
      navigationOpen={false}
      navigationWidth={250}
      ariaLabels={appLayoutLabels}
      onNavigationChange={(event) => {
        if (props.onNavigationChange) {
          props.onNavigationChange(event);
        }
      }}
      onToolsChange={(event) => {
        if (props.onToolsChange) {
          props.onToolsChange(event);
        }
      }}
    />
  );
}

export const CounterLink = ({ children }) => {
  return (
    <Link variant="awsui-value-large" href="#">
      {children}
    </Link>
  );
};

export const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box variant="h3">{label}</Box>
    <div>
      <h1>{children}</h1>
    </div>
  </div>
);
