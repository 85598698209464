import React from "react";
import "./Card.scss"

const CardHeader = (props) => {

    return (
        <div className="cardHeader">
            <div className="card-body">
                <h2 style={{paddingBottom: 5}}>
                    <b>{props.title} : </b>
                    <b>{props.aggregationNumber}</b>
                </h2>
                <div className="subtitle">
                    <p style={{fontSize: 12}}>
                        {props.subTitle}
                    </p>
                </div>
            </div>
        </div>
    )
}
export default CardHeader;