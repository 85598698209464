import {API} from "aws-amplify";
import {getInputContext} from "../utils/api-utils";
import {getFlashBar} from "../utils/common-utils";
import {CASE_MANAGEMENT_SERVICE_API, ADD_COMMENT_TO_CASE} from "../constants/api-paths";
import {INTERNAL_ERROR_FLASH_BAR_PROPS, NOT_AUTHORIZED_FLASH_BAR_PROPS} from "../constants/common";

let request = {
  "useCaseName": "",
  "caseId" : "",
  "clientId" : "ComplianceDashboard",
  "comment" : "",
  "commentor" : ""
};

export async function addCommentToCase(useCaseName, caseId, comment) {

    getInputContext().then(input => {
        request.useCaseName = useCaseName;
        request.caseId = caseId;
        request.comment = comment;
        request.commentor = input.body.userAlias;
       request.commentor = request.commentor.split("_")[1];
        input.body.requestType = "AddCommentToCase";
        input.body.requestString = JSON.stringify(request);
        console.log("Input for add comment = " + JSON.stringify(request));
        API.post(CASE_MANAGEMENT_SERVICE_API, ADD_COMMENT_TO_CASE, input).then(resp => {
            console.log(ADD_COMMENT_TO_CASE + " API was called, response : " + JSON.stringify(resp));
            if (resp.status === 200) {
                return "Success";
            } else{
                //setContent(getFlashBar(INTERNAL_ERROR_FLASH_BAR_PROPS));
            }
        });
    });

}
