import React, { useState, useEffect } from "react";
import {
  TextContent,
  BreadcrumbGroup,
  Header,
  Container,
  ColumnLayout,
  Button,
  SpaceBetween,
  Link,
  Box,
  Select,
  Tabs,
  Table,
} from "@amzn/awsui-components-react";
import { SelectProps } from "@amzn/awsui-components-react/polaris/select";
import {
  CustomAppLayout,
  ValueWithLabel,
} from "../../common/common-components";
import { summary } from "../../common/breadcrumbs";
import {
  ANOMALIES_SELECT_OPTIONS,
  SUMMARY_TABLE_OVERALL_ITEMS,
  FORM_1042S_ITEMS,
  FORM_1099_ITEMS,
} from "../../config/table/summary-table-config";
import { getCasesByStatusAndOwnership } from "../../api/getCasesByStatusAndOwnership";
import {useNavigate} from "react-router-dom";
import {ROUTE_STRYFE_ACTION_CENTRE} from "src/constants/route-paths";

const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={summary}
    expandAriaLabel="Show path"
    ariaLabel="Breadcrumbs"
  />
);

const FormOverallTable = (
  <Table
    columnDefinitions={[
      {
        id: "anomalyType",
        header: "Anomaly Type",
        cell: (item) => item.anomalyType,
      },
      {
        id: "anomalyCount",
        header: "Anomaly Count",
        cell: (item) => <a href={item.anomalyLink}> {item.anomalyCount}</a>,
      },
    ]}
    items={SUMMARY_TABLE_OVERALL_ITEMS}
    loadingText="Loading resources"
    sortingDisabled
    empty={
      <Box textAlign="center" color="inherit">
        <b>No resources</b>
        <Box padding={{ bottom: "s" }} variant="p" color="inherit">
          No resources to display.
        </Box>
      </Box>
    }
    header={<Header> Overall Status </Header>}
  />
);

const FormOneTable = (
  <Table
    columnDefinitions={[
      {
        id: "anomalyType",
        header: "Anomaly Type",
        cell: (item) => item.anomalyType,
      },
      {
        id: "anomalyCount",
        header: "Anomaly Count",
        cell: (item) => <a href={item.anomalyLink}> {item.anomalyCount}</a>,
      },
    ]}
    items={FORM_1042S_ITEMS}
    loadingText="Loading resources"
    sortingDisabled
    empty={
      <Box textAlign="center" color="inherit">
        <b>No resources</b>
        <Box padding={{ bottom: "s" }} variant="p" color="inherit">
          No resources to display.
        </Box>
        <Button>Create resource</Button>
      </Box>
    }
    header={<Header> 1042S Anomalies </Header>}
  />
);

const FormTwoTable = (
  <Table
    columnDefinitions={[
      {
        id: "anomalyType",
        header: "Anomaly Type",
        cell: (item) => item.anomalyType,
      },
      {
        id: "anomalyCount",
        header: "Anomaly Count",
        cell: (item) => <a href={item.anomalyLink}> {item.anomalyCount}</a>,
      },
    ]}
    items={FORM_1099_ITEMS}
    loadingText="Loading resources"
    sortingDisabled
    empty={
      <Box textAlign="center" color="inherit">
        <b>No resources</b>
        <Box padding={{ bottom: "s" }} variant="p" color="inherit">
          No resources to display.
        </Box>
      </Box>
    }
    header={<Header> 1099 Anomalies </Header>}
  />
);

const ShowTabs = (isShowTabs) => {
  return (
    <Tabs
      tabs={[
//         {
//           label: "Overall",
//           id: "first",
//           content: FormOverallTable,
//         },
        {
          label: "1042S",
          id: "second",
          content: FormOneTable,
        },
        {
          label: "1099",
          id: "third",
          content: FormTwoTable,
        },
      ]}
      variant="container"
    />
  );
};

const SummaryDashboard = (params) => {
  const defaultOption = [{ label: "All anomalies", value: "1" }];
  const [selectedOption, setSelectedOption] =
    useState<SelectProps.Option>(defaultOption[0]);
  const [showTabs, setShowTabs] = useState(false);
  const navigate = useNavigate();

console.log("returning summary page");

  return (
    <CustomAppLayout
      breadcrumbs={<Breadcrumbs />}
      content={
        <div>
          <TextContent>
            <h1>Compliance 360</h1>
          </TextContent>
          <br />
          <TextContent>
            <h4>See statistics for</h4>
          </TextContent>

          <Select
            selectedOption={selectedOption}
            onChange={({ detail }) => {
              setSelectedOption(detail.selectedOption);
              if(detail.selectedOption.value === "3")
              {
                  window.location.href = ROUTE_STRYFE_ACTION_CENTRE
                  setShowTabs(false);
              }
              else {
                  setShowTabs(true);
              }
            }}
            options={ANOMALIES_SELECT_OPTIONS}
            selectedAriaLabel="Selected"
          />
          <br />
          <br />
          <div>{showTabs ? <ShowTabs isShowTabs={showTabs} /> : <br />}</div>
        </div>
      }
    />
  );
};

export default SummaryDashboard;
