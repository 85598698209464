import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import {
  ROUTE_PATH_HOME_PAGE,
  ROUTE_PATH_NOT_FOUND_PAGE,
  ROUTE_DEFAULT,
  ROUTE_SUMMARY,
  ROUTE_CASES,
  ROUTE_CASES_UNDERWITHHOLDING,
  ROUTE_CASES_1099_UNDERWITHHOLDING,
  ROUTE_CASES_1042S_UNDERWITHHOLDING,
  ROUTE_STRYFE_ACTION_CENTRE,
} from "../constants/route-paths";
import  SummaryDashboard from "./view/Summary";
import  Cases from "./view/CaseDetails";
import  PageNotFound from "./view/PageNotFound";
import {checkUserAuthorization} from "../api/checkUserAuthorization";
import StryfeDashboard from "src/components/stryfe/StryfeDashboard"; // stryfe dashboard


const Router = (domain) => {
  return (
    <Routes>
      <Route
        path={ROUTE_PATH_HOME_PAGE}
        element={
          <Page
            pageName={"SummaryDashboard"}
            content={<SummaryDashboard {...domain} />}
          />
        }
      />
      <Route path={ROUTE_STRYFE_ACTION_CENTRE}
             element={
                 <Page pageName={"StryfeActionCentre"}
                       content={<StryfeDashboard/>}
                 />
             }
      />
      <Route
        path={ROUTE_SUMMARY}
        element={
          <Page
            pageName={"SummaryDashboard"}
            content={<SummaryDashboard {...domain} />}
          />
        }
      />
      <Route
        path={ROUTE_CASES}
        element={
          <Page
            pageName={"Cases"}
            content={<Cases domain={domain} formType="All" anomalyType="All" />}
          />
        }
      />
      <Route
        path={ROUTE_CASES_UNDERWITHHOLDING}
        element={
          <Page
            pageName={"Cases"}
            content={
              <Cases
                domain={domain}
                formType="All"
                anomalyType="UnderWithholding"
              />
            }
          />
        }
      />
      <Route
        path={ROUTE_CASES_1099_UNDERWITHHOLDING}
        element={
          <Page
            pageName={"Cases"}
            content={
              <Cases
                domain={domain}
                formType="1099"
                anomalyType="UnderWithholding"
              />
            }
          />
        }
      />
      <Route
        path={ROUTE_CASES_1042S_UNDERWITHHOLDING}
        element={
          <Page
            pageName={"Cases"}
            content={
              <Cases
                domain={domain}
                formType="1042S"
                anomalyType="UnderWithholding"
              />
            }
          />
        }
      />
      <Route path={ROUTE_DEFAULT} element={<PageNotFound />} />
      <Route path={ROUTE_PATH_NOT_FOUND_PAGE} element={<PageNotFound />} />
    </Routes>
  );
};

function Page({ pageName, content }) {
  const [returnContent, setContent] = useState(content);
  useEffect(() => {
    checkUserAuthorization(pageName, setContent);
  }, [pageName]);
  return returnContent;
}

export default Router;
