import React from "react";
import {CASES_DATE_RANGE_MAP, DUPLICATE_REJECTED} from "src/components/StryfeAPI/Constants";

const CompletedCaseWidget = (props) => {

    function setInvoicesVisible() {
        props.data.onClick(true);
    }

    function updateStartDate(date: string) {
        props.data.updateStartDate(date);
    }

    function updateEndDate(date: string) {
        props.data.updateEndDate(date);
    }

    function updateCaseStatus(caseStatus: string) {
        props.data.updateCaseStatus(caseStatus);
    }

    return (
        <div onClick={setInvoicesVisible}>
            <div onClick={() => {
                updateStartDate(CASES_DATE_RANGE_MAP.completedCasesDateRange.startDate),
                updateEndDate(CASES_DATE_RANGE_MAP.completedCasesDateRange.endDate),
                updateCaseStatus(props.data.caseStatus)
            }}>
                <h4 className="blue-600">Completed : {props.data.completedCaseWidgetData.completedCasesSummary.count}</h4>
            </div>
            <div onClick={() => {
                updateStartDate(CASES_DATE_RANGE_MAP.duplicateCasesDateRange.startDate),
                updateEndDate(CASES_DATE_RANGE_MAP.duplicateCasesDateRange.endDate),
                updateCaseStatus(DUPLICATE_REJECTED)
            }}>
               <h4 className="blue-600">Duplicates : {props.data.completedCaseWidgetData.duplicateCasesSummary.count}</h4>
            </div>
            <div onClick={() => {
                updateStartDate(CASES_DATE_RANGE_MAP.completedCasesDateRange.startDate),
                updateEndDate(CASES_DATE_RANGE_MAP.completedCasesDateRange.endDate),
                updateCaseStatus(props.data.caseStatus)
            }}>
               <h4 className="blue-600">Completed Others : {props.data.completedCaseWidgetData.nonServiceCasesSummary.count}</h4>
            </div>
            <br/>
        </div>
    )
}
export default CompletedCaseWidget;