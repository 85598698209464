export const ANOMALIES_SELECT_OPTIONS = [
  {
    label: "All anomalies",
    value: "1",
  },
  {
    label: "FAS5 anomalies",
    value: "2",
  },
  {
    label:"Brazil - Senna",
    value:"3"
  }
];

export const SUMMARY_TABLE_OVERALL_ITEMS = [
  {
    anomalyType: "Under-Withholding",
    anomalyCount: 30000,
    anomalyLink: "/summary/cases/underwithholding",
  }
];

export const FORM_1042S_ITEMS = [
  {
    anomalyType: "Under-Withholding",
    anomalyCount: 35228,
    anomalyLink: "/summary/cases/1042S/underwithholding",
  },
];

export const FORM_1099_ITEMS = [
  {
    anomalyType: "UnderWithholding",
    anomalyCount: 937,
    anomalyLink: "/summary/cases/1099/underwithholding",
  }
];
