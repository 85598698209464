import React from "react";
import { addColumnSortLabels } from "../../common/labels";
import { PropertyFilterProperty } from "@amzn/awsui-collection-hooks";
import { StatusIndicator } from "@amzn/awsui-components-react";

export const COLUMN_DEFINITIONS = addColumnSortLabels([
  {
    id: "useCaseName",
    header: "Usecase Name",
    cell: (item) => item['caseId_partitionKey'],
    minWidth: 100,
  },
  {
    id: "status",
    cell: (item) => (
      <StatusIndicator
        type={
          item.Status === "OPEN"
            ? "info"
            : ((item.Status === "CLOSED")
            ? "success"
            : "stopped")
        }
      >
        {item.Status}
      </StatusIndicator>
    ),
    header: "Status",
    minWidth: 160,
  },
  {
    id: "team",
    header: "Team",
    cell: (item) => item['Team'],
    minWidth: 100,
  },
  {
    id: "FAS5AnomalyType",
    header: "FAS5AnomalyType",
    cell: (item) => item['1042s - fas5 anomaly'],
    minWidth: 100,
  },
  {
    id: "clientId",
    header: "Client ID",
    cell: (item) => item['client id'],
    minWidth: 100,
  },
  {
    id: "accountId",
    header: "Account ID",
    cell: (item) => item['account id'],
    minWidth: 100,
  },
  {
    id: "identityKey",
    header: "Identity Key",
    cell: (item) => item['identity key'],
    minWidth: 100,
  },
  {
    id: "formId",
    header: "Form ID",
    cell: (item) => item['form id'],
    minWidth: 100,
  },
  {
    id: "taxYear",
    sortingField: "tax year",
    header: "Tax Year",
    cell: (item) => item['tax year'],
    minWidth: 100,
  },
  {
    id: "timsFormType",
    header: "TIMS Form Type",
    cell: (item) => item['tims form type'],
    minWidth: 100,
  },
  {
    id: "reportingFormType",
    header: "Reporting Form Type",
    cell: (item) => item['reporting form type'],
    minWidth: 100,
  },
  {
    id: "formStatus",
    header: "Form Status",
    cell: (item) => item['form status'],
    minWidth: 100,
  },
  {
    id: "identityLegalName",
    header: "Identity Legal Name",
    cell: (item) => item['identity legal name'],
    minWidth: 100,
  },
  {
    id: "taxIncomeType",
    header: "Tax Income Type",
    cell: (item) => item['tax income type'],
    minWidth: 100,
  },
  {
    id: "email",
    header: "Email",
    cell: (item) => item.email,
    minWidth: 100,
  },
  {
    id: "country",
    header: "Country",
    cell: (item) => item.country,
    minWidth: 100,
  },
  {
    id: "grossAmount",
    sortingField: "gross amount",
    header: "Gross Amount",
    cell: (item) => item['gross amount'],
    minWidth: 100,
  },
  {
    id: "withholdingRate",
    sortingField: "withholding rate",
    header: "Withholding Rate",
    cell: (item) => item['withholding rate'],
    minWidth: 100,
  },
  {
    id: "taxRateMismatch",
    header: "Tax Rate Mismatch",
    cell: (item) => item['tax rate mismatch'],
    minWidth: 100,
  },
  {
    id: "latestIdentityKey",
    header: "Latest Identity Key",
    cell: (item) => item['latest identity key'],
    minWidth: 100,
  },
  {
    id: "latestIdentityWithholdingRate",
    sortingField: "latest identity withholding rate",
    header: "Latest Identity Withholding Rate",
    cell: (item) => item['latest identity withholding rate'],
    minWidth: 100,
  },
  {
    id: "federalWithholdingAmount",
    sortingField: "federal withholding amount",
    header: "Federal Withholding Amount",
    cell: (item) => item['federal withholding amount'],
    minWidth: 100,
  },
  {
    id: "taxPaidByWithholdingAgent",
    sortingField: "tax paid by withholding agent",
    header: "Tax Paid By Withholding Agent",
    cell: (item) => item['tax paid by withholding agent'],
    minWidth: 100,
  },
  {
    id: "expectedWithholdingAmount",
    sortingField: "expected withholding amount",
    header: "Expected Withholding Amount",
    cell: (item) => item['expected withholding amount'],
    minWidth: 100,
  },
  {
    id: "withholdingAmountDiff",
    sortingField: "withholding amount diff",
    header: "Withholding Amount Diff",
    cell: (item) => item['withholding amount diff'],
    minWidth: 100,
  },
  {
    id: "isMasterOverrideApplied",
    header: "Is MasterOverride Applied",
    cell: (item) => item['is master override applied'],
    minWidth: 100,
  },
  {
    id: "isBnoticeReceived",
    header: "Is Bnotice Received",
    cell: (item) => item['is b notice received'],
    minWidth: 100,
  },
  {
    id: "formExpiryDate",
    header: "Form Expiry Date",
    cell: (item) => item['form expiry date'],
    minWidth: 100,
  },
  {
    id: "treatyClaimCountryCode",
    header: "Treaty Claim Country Code",
    cell: (item) => item['treaty claim country code'],
    minWidth: 100,
  },
  {
    id: "treatyClaimPercent",
    header: "Treaty Claim Percent",
    cell: (item) => item['treaty claim percent'],
    minWidth: 100,
  },
  {
    id: "tinValidationStatus",
    header: "TIN Validation Status",
    cell: (item) => item['tin validation status'],
    minWidth: 100,
  },
  {
    id: "w8BeneficialOwnerType",
    header: "W8 Beneficial Owner Type",
    cell: (item) => item['w8 beneficial owner type'],
    minWidth: 100,
  },
  {
    id: "isTrust",
    header: "IsTrust",
    cell: (item) => item['is trust'],
    minWidth: 100,
  },
  {
    id: "genericTaxStatus",
    header: "Generic Tax Status",
    cell: (item) => item['generic tax status'],
    minWidth: 100,
  },
]);

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "FAS 5 Anomaly Cases properties",
    options: [
      { id: "useCaseName", label: "UseCase Name" },
      { id: "status", label: "Status" },
      { id: "team", label: "Team" },
      { id: "FAS5AnomalyType", label: "FAS5AnomalyType" },
      { id: "clientId", label: "Client ID" },
      { id: "accountId", label: "Account ID" },
      { id: "identityKey", label: "FAS5AnomalyType" },
      { id: "formId", label: "Form ID" },
      { id: "taxYear", label: "Tax Year" },
      { id: "timsFormType", label: "TIMS Form Type" },
      { id: "reportingFormType", label: "Reporting Form Type" },
      { id: "formStatus", label: "Form Status" },
      { id: "identityLegalName", label: "Identity Legal Name" },
      { id: "taxIncomeType", label: "Tax Income Type" },
      { id: "email", label: "Email" },
      { id: "country", label: "Country" },
      { id: "grossAmount", label: "Gross Amount" },
      { id: "withholdingRate", label: "Withholding Rate" },
      { id: "taxRateMismatch", label: "Tax Rate Mismatch" },
      { id: "latestIdentityKey", label: "Latest Identity Key" },
      {
        id: "latestIdentityWithholdingRate",
        label: "Latest Identity WithholdingRate",
      },
      { id: "federalWithholdingAmount", label: "Federal Withholding Amount" },
      {
        id: "taxPaidByWithholdingAgent",
        label: "Tax Paid By Withholding Agent",
      },
      { id: "expectedWithholdingAmount", label: "Expected Withholding Amount" },
      { id: "withholdingAmountDiff", label: "Withholding Amount Difference" },
      { id: "isMasterOverrideApplied", label: "Is MasterOverride Appliedf" },
      { id: "isBnoticeReceived", label: "Is Bnotice Received" },
      { id: "treatyClaimCountryCode", label: "Form Expiry Date" },
      { id: "treatyClaimCountryCode", label: "Treaty Claim Country Code" },
      { id: "treatyClaimPercent", label: "Treaty Claim Percent" },
      { id: "tinValidationStatus", label: "Tin Validation Status" },
      { id: "w8BeneficialOwnerType", label: "W8 Beneficial Owner Type" },
      { id: "isTrust", label: "IsTrust" },
      { id: "genericTaxStatus", label: "Generic Tax Status" },
    ],
  },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 100,
  visibleContent: [
    "useCaseName",
    "status",
    "team",
    "FAS5AnomalyType",
    "clientId",
    "accountId",
    "formId",
    "taxYear",
    "country",
    "grossAmount",
    "withholdingRate",
    "taxRateMismatch",
    "federalWithholdingAmount",
    "expectedWithholdingAmount",
    "withholdingAmountDiff"
  ],
  wrapLines: false,
};

export const FILTERING_PROPERTIES: PropertyFilterProperty[] = [
  {
    propertyLabel: "UseCase Name",
    key: "caseId_partitionKey",
    groupValuesLabel: `UseCase Name values`,
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Status",
    key: "Status",
    groupValuesLabel: `Status values`,
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Team",
    key: "Team",
    groupValuesLabel: `Team values`,
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "FAS5AnomalyType",
    key: "1042s - fas5 anomaly",
    groupValuesLabel: `FAS5AnomalyType values`,
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Client Id",
    key: "client id",
    groupValuesLabel: `ClientId values`,
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Account Id",
    key: "account id",
    groupValuesLabel: `AccountId values`,
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Form Id",
    key: "form id",
    groupValuesLabel: `Form Id values`,
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Tax Year",
    key: "tax year",
    groupValuesLabel: `Tax Year values`,
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Country",
    key: "country",
    groupValuesLabel: `Country values`,
    operators: [":", "!:", "=", "!="],
  },

  {
    propertyLabel: "Gross Amount",
    key: "gross amount",
    groupValuesLabel: `Gross Amount values`,
    operators: ["<", "<=", ">", ">=", "="],
  },
  {
    propertyLabel: "Withholding Rate",
    key: "withholding rate",
    groupValuesLabel: `Withholding Rate values`,
    operators: ["<", "<=", ">", ">=", "="],
  },
  {
    propertyLabel: "Federal Withholding Amount",
    key: "federal withholding amount",
    groupValuesLabel: `Federal Withholding Amount values`,
    operators: ["<", "<=", ">", ">=", "="],
  },
  {
    propertyLabel: "Expected Withholding Amount",
    key: "expected withholding amount",
    groupValuesLabel: `Expected Withholding Amount values`,
    operators: ["<", "<=", ">", ">=", "="],
  },
    {
      propertyLabel: "Withholding Amount Difference",
      key: "withholding amount diff",
      groupValuesLabel: `Withholding Amount Difference values`,
      operators: ["<", "<=", ">", ">=", "="],
    },
];
