import React from "react";
import { CollectionPreferences } from "@amzn/awsui-components-react";
import {VISIBLE_CONTENT_OPTIONS} from "../ads-fas5-config";

export const PAGE_SIZE_OPTIONS =  [
  { value: 10, label: "10 Cases" },
  { value: 30, label: "30 Cases" },
  { value: 50, label: "50 Cases" },
  { value: 100, label: "100 Cases" },
  { value: 500, label: "500 Cases" },
  { value: 1000, label: "1000 Cases" },
];

export const Preferences = ({
  preferences,
  setPreferences,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={false}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible columns",
      options: visibleContentOptions,
    }}
  />
);

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  filteringAriaLabel: "your choice",
  dismissAriaLabel: "Dismiss",
  filteringPlaceholder: "Search",
  groupValuesText: "Values",
  groupPropertiesText: "Properties",
  operatorsText: "Operators",
  operationAndText: "and",
  operationOrText: "or",
  operatorLessText: "Less than",
  operatorLessOrEqualText: "Less than or equal",
  operatorGreaterText: "Greater than",
  operatorGreaterOrEqualText: "Greater than or equal",
  operatorContainsText: "Contains",
  operatorDoesNotContainText: "Does not contain",
  operatorEqualsText: "Equals",
  operatorDoesNotEqualText: "Does not equal",
  editTokenHeader: "Edit filter",
  propertyText: "Property",
  operatorText: "Operator",
  valueText: "Value",
  cancelActionText: "Cancel",
  applyActionText: "Apply",
  allPropertiesLabel: "All properties",
  tokenLimitShowMore: "Show more",
  tokenLimitShowFewer: "Show fewer",
  clearFiltersText: "Clear filters",
  removeTokenButtonAriaLabel: () => "Remove token",
  enteredTextLabel: (text) => `Use: "${text}"`,
};
export const DEFAULT_COLUMN_WIDTH = 150;