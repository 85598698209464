const AppConfig = {
  development: {
    REGION: "us-west-2",
    DOMAIN: "beta",
    USER_POOL_ID: "us-west-2_5jRqwDPEa",
    APP_CLIENT_ID: "en9rhrl5kmn5h448adijpti6k",
    OAUTH: {
      domain: "compliance-dashboard.auth.us-west-2.amazoncognito.com",
      scope: ["openid"],
      redirectSignIn: "http://localhost:4321/",
      redirectSignOut: "http://localhost:4321/",
      responseType: "code",
    },
    API_GATEWAY_ENDPOINTS: {
      endpoints: [
        {
          name: "CaseManagementServiceAPI",
          endpoint:
            "https://cn4k7gnpu0.execute-api.us-west-2.amazonaws.com/beta",
        },
      ],
    },
  },
  beta: {
    REGION: "us-west-2",
    DOMAIN: "beta",
    USER_POOL_ID: "us-west-2_5jRqwDPEa",
    APP_CLIENT_ID: "en9rhrl5kmn5h448adijpti6k",
    OAUTH: {
      domain: "compliance-dashboard.auth.us-west-2.amazoncognito.com",
      scope: ["openid"],
      redirectSignIn: "https://beta.compliance-dashboard.tax.amazon.dev/",
      redirectSignOut: "https://beta.compliance-dashboard.tax.amazon.dev/",
      responseType: "code",
    },
    API_GATEWAY_ENDPOINTS: {
      endpoints: [
        {
          name: "CaseManagementServiceAPI",
          endpoint:
            "https://cn4k7gnpu0.execute-api.us-west-2.amazonaws.com/beta",
        },
      ],
    },
  },
  gamma: {
    REGION: "us-east-1",
    DOMAIN: "gamma",
    USER_POOL_ID: "us-east-1_r3tRh4Odg",
    APP_CLIENT_ID: "5pbbqllf003ohlmikv77uvc6tf",
    OAUTH: {
      domain: "compliance-dashboard.auth.us-east-1.amazoncognito.com",
      scope: ["openid"],
      redirectSignIn: "https://d2jlise6kfqzy4.cloudfront.net/",
      redirectSignOut: "https://d2jlise6kfqzy4.cloudfront.net/",
      responseType: "code",
    },
    API_GATEWAY_ENDPOINTS: {
      endpoints: [
        {
          name: "CaseManagementServiceAPI",
          endpoint:
            "https://5hgk1yizi7.execute-api.us-east-1.amazonaws.com/gamma",
        },
      ],
    },
  },
  prod: {
    REGION: "us-east-1",
    DOMAIN: "prod",
    USER_POOL_ID: "us-east-1_TzjXuAJcp",
    APP_CLIENT_ID: "629h10bipha3vme5ubg0p4qn2v",
    OAUTH: {
      domain: "compliance-dashboard-prod.auth.us-east-1.amazoncognito.com",
      scope: ["openid"],
      redirectSignIn: "https://prod.compliance-dashboard.tax.amazon.dev/",
      redirectSignOut: "https://prod.compliance-dashboard.tax.amazon.dev/",
      responseType: "code",
    },
    API_GATEWAY_ENDPOINTS: {
      endpoints: [
        {
          name: "CaseManagementServiceAPI",
          endpoint:
            "https://i78pesev01.execute-api.us-east-1.amazonaws.com/prod",
        },
      ],
    },
  }
};

export default AppConfig;
