export const common = [
  {
    text: "Home",
    href: "/summary",
  },
];

export const summary = [
  ...common,
  {
    text: "Compliance 360",
    href: "/summary",
  },
];

export const openCases = [
  ...summary,
  {
    text: "Open Cases",
    href: "/summary/cases",
  },
];
