import React, { useState } from "react";
import { API } from "aws-amplify";
import { getInputContext } from "../utils/api-utils";
import { CASE_MANAGEMENT_SERVICE_API } from "../constants/api-paths";

let request = {
  useCaseName: "",
  clientId: "ComplianceDashboard",
  pageSize: 100,
  paginationToken: null,
  team: "IACC"
};

let result = {
  data: {},
  lastEvaluatedKey: null,
};

export async function getCasesByStatusAndOwnership(formType, lastEvaluatedKey) {
  const inputContext = await getInputContext();
  let suffix = "";
  inputContext.body.requestType = "GetCasesByStatusAndOwnership";
  request.paginationToken = lastEvaluatedKey;
  if(formType === "1099") {
    request.useCaseName = "ADS#FAS5_US_1099";
  } else {
   request.useCaseName = "ADS#FAS5_US_1042S";
  }
  inputContext.body.requestString = JSON.stringify(request);
  console.log("Input = " + JSON.stringify(inputContext));
  const resp = await API.post(
    CASE_MANAGEMENT_SERVICE_API,
    "/getcasesbystatusandownership",
    inputContext
  );

  if (resp.statusCode === "200") {
    const parsedjson = JSON.parse(resp.body);
    console.log(
      "Response json matchedCases = " + JSON.stringify(parsedjson.matchedCases)
    );
    console.log("token sent = " + JSON.stringify(parsedjson.paginationToken));
    console.log("data sent = " + JSON.stringify(parsedjson.matchedCases));

    result.data = parsedjson.matchedCases;
    result.lastEvaluatedKey = parsedjson.paginationToken;
    return result;
  } else {
    console.log("No status code" + JSON.stringify(resp));
  }
}
