import * as React from "react";
import {useEffect, useState} from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import {
    Box,
    Button,
    DatePicker,
    FormField,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    TextFilter
} from "@amzn/awsui-components-react";
import {getStryfeCasesByStatusAndDateRange} from "src/api/getStryfeCasesByStatusAndDateRange";
import {useLocalStorage} from "src/components/table/local-storage";
import {paginationLabels} from "src/common/labels";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {DUPLICATE_REJECTED} from "src/components/StryfeAPI/Constants";
import {TableEmptyState, TableNoMatchState} from "src/components/table/table-common-components";
import {Preferences, PROPERTY_FILTERING_I18N_CONSTANTS} from "src/config/table/common/case-table-config";
import {convertCamelCaseToEnglishCase, convertENtoBRNotation, exportToExcel, formatDate} from "src/utils/common-utils";
import {getFilterCounterText} from "src/components/table/table-counter-strings";

function StryfeInvoiceTable(props) {
    const [
        selectedItems,
        setSelectedItems
    ]: any[] = React.useState([]);
    const {showSelectionBoxes = true} = props || {};
    const [evalKey, setEvalKey] = useState(null);
    const [isIntialised, setIsInitialised] = useState(true);
    const [isTrigger, setIsTrigger] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [startDate, setStartDate] = useState(props.startDate);
    const [endDate, setEndDate] = useState(props.endDate);
    const [caseStatus, setCaseStatus] = useState(props.caseStatus);
    const [preferences, setPreferences] = useState(props.defaultPreferences);
    const [selectionTypeProps, setSelectionTypeProps] = useState({});
    const [invoices, setInvoices]: any[] = useState([]);
    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        paginationProps,
        propertyFilterProps,
    } = useCollection(invoices,
        {
        propertyFiltering: {
            filteringProperties: props.filteringProperties,
            empty: <TableEmptyState resourceName="No cases"/>,
            noMatch: (
                <TableNoMatchState onClearFilter={() => actions.setFiltering("")}/>
            ),
        },
        pagination: {pageSize: preferences.pageSize},
        selection: {},
    });

    /**
     *  Mapping Variable
     *  ComplianceDashboardCaseManagement attributes mapped to LaserFiche Attributes
     */

    const laserFicheAttributeMapping = new Map([
       ["barcode","Invoice"],
       ["orgName","Amazon Location"],
       ["invoiceDate","Invoice Date"],
       ["invoiceNumber","Invoice Number"],
       ["supplierName","Supplier Name"],
       ["invoiceAmount","Total Invoice Amount"],
       ["invoiceCurrency","Currency"],
       ["invoiceSeries","Invoice Series"],
       ["fiscalDocumentModel","Invoice Model"],
       ["sopNumber","SOP"],
       ["addressee","Addressee"],
       ["comment","Comments"]
    ]);

    function startDateWithTime(date) {
        setStartDate(date + ".00:00:00");
    }

    function endDateWithTime(date) {
        setEndDate(date + ".23:59:59");
    }

    const exportInvoices = () => {
        let invoiceDataForExcel:any = [];
        for(let i = 0;i<invoices.length;i++)
        {
             invoiceDataForExcel.push(addFilteredDataFromInvoice(invoices[i], preferences));
        }
        exportToExcel(invoiceDataForExcel,"Brazil "+caseStatus+" "+new Date().toString());
    }

    function addFilteredDataFromInvoice(invoice, preferences){
        let newInvoice = {};

        preferences.visibleContent.forEach(item => {
            if (!(newInvoice.hasOwnProperty(item))) {
                newInvoice[convertCamelCaseToEnglishCase(item)] = (invoice[item] ? invoice[item] : "");
            }
        })

        return newInvoice;
    }

    /**
     * Takes date in format DD/MM/YYYY and converts to MM/DD/YYYY
     */
    function formatDateToMMDDYYYY(dateString) {
        let parts = dateString.split('/');
        let day = parts[0];
        let month = parts[1];
        let year = parts[2];
        return month + '/' + day + '/' + year;
    }

    useEffect(() => {
        setIsLoading(true);
        setInvoices([]);
        setEvalKey(null);
        setIsInitialised(true);
        setSelectedItems([]);
        setIsTrigger(prevState => !prevState);
        props.onClick(null);
    }, [startDate, endDate, caseStatus]);
    useEffect(() => {
        let returnedCases: any = {};
        evalKey !== null ? setIsLoading(false) : setIsLoading(true);
        const fetchData = async () => {
            if (isIntialised || evalKey !== null) {
                returnedCases = await getStryfeCasesByStatusAndDateRange(evalKey, startDate, endDate, caseStatus);
                if (returnedCases !== null) {
                    setInvoices([...invoices, ...returnedCases.data]);
                    setIsInitialised(false);
                    setIsLoading(false);
                    if (returnedCases.lastEvaluatedKey !== null) {
                        setEvalKey(returnedCases.lastEvaluatedKey);
                    }
                }
            }
        };
        fetchData();
    }, [evalKey, isTrigger]);
    useEffect(() => {
        if (selectedItems !== null && selectedItems.length > 0) {
            props.onClick(selectedItems[0]);
        }
    }, [selectedItems])

    useEffect(() => {
        setStartDate(props.startDate);
        setEndDate((props.endDate));
        setCaseStatus(props.caseStatus);
        setPreferences(props.defaultPreferences);
        if(showSelectionBoxes) {
            setSelectionTypeProps({selectionType: 'single'});
        } else {
            setSelectionTypeProps({});
        }
    }, [props.startDate, props.endDate, props.caseStatus,
     props.preferences, props.showSelectionBoxes]);

    return (
        <div>
            <div className="flexbox-container">
                <div className="margin">
                    <FormField label="Start Date" constraintText="Use YYYY/MM/DD format.">
                        <DatePicker
                            onChange={(changeDetail) => startDateWithTime(changeDetail.detail.value)}
                            value={startDate}
                            nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="StartDate"
                        />
                    </FormField>
                </div>
                <div className="margin">
                    <FormField label="End Date" constraintText="Use YYYY/MM/DD format.">
                        <DatePicker
                            onChange={(changeDetail) => endDateWithTime(changeDetail.detail.value)}
                            value={endDate}
                            nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="EndDate"
                        />
                    </FormField>
                </div>
                <div style={{width:"100%"}}>
                {/*<span>Filter</span>*/}
                    {/*<SpaceBetween size="s" direction="horizontal">*/}
                    {/*<Button>4R</Button>*/}
                    {/*<Button>5B</Button>*/}
                    {/*<Button>MG</Button>*/}
                    {/*<Button>MN</Button>*/}
                    {/*<Button>No Payment</Button>*/}
                    {/*</SpaceBetween>*/}
                <SpaceBetween size="s" direction="horizontal" className="spaceBetweenContainer" justify-content="right">
                    <Button variant="primary"  onClick={() =>exportInvoices()} disabled={isLoading}>Download</Button>
                </SpaceBetween>
                </div>
            </div>
            <Table
                {...collectionProps}
                onSelectionChange={({detail}) => {
                    setSelectedItems(detail.selectedItems)
                }
                }
                loading={isLoading}
                selectedItems={selectedItems}
                columnDefinitions={props.columnDefinition}
                items={items}
                loadingText="Loading resources"
                {...selectionTypeProps}
                visibleColumns={preferences.visibleContent}
                pagination={
                    <Pagination {...paginationProps} ariaLabels={paginationLabels}/>
                }
                resizableColumns={true}
                empty={
                    <Box textAlign="center" color="inherit">
                        <b>No resources</b>
                        <Box
                            padding={{bottom: "s"}}
                            variant="p"
                            color="inherit"
                        >
                            No resources to display.
                        </Box>
                    </Box>
                }
                wrapLines={preferences.wrapLines}
                filter={
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                        {...propertyFilterProps}
                        countText={getFilterCounterText(filteredItemsCount)}
                        expandToViewport={true}
                    />
                }
                preferences={
                    <Preferences
                        preferences={preferences}
                        setPreferences={setPreferences}
                        visibleContentOptions={props.visibleContentOptions}
                    />
                }
            ></Table>
        </div>
    );
}

export default StryfeInvoiceTable;