import {
    DEFAULT_STRYFE_CASE_START_DATE, STRYFE_RED_CASES_END_DATE_OFFSET,
    STRYFE_YELLOW_CASES_END_DATE_OFFSET,
    STRYFE_YELLOW_CASES_START_DATE_OFFSET
} from "src/constants/common";
import {getFormattedDayEndDate, getFormattedDayStartDate, getOnCallContactLink} from "src/utils/common-utils";
import React from "react";

export const STRYFE_CASE_STATUS_HEADING_MAP = {
    "PENDING": "Pending Cases",
    "PENDING TAX CLASSIFICATION":"Pending Tax Classification",
    "PENDING REMITTANCE":"Pending Remittance",
    "COMPLETED REMITTANCE":"Completed Remittance"
};

export const STRYFE_CLIENTID:string = "ComplianceDashboard";
export const STRYFE_USE_CASE_NAME:string = "STRYFE#BR";
export const STRYFE_GET_CASE_BY_STATUS_CLIENTID:string = "STRYFE";
export const PENDING_CASES:string = "PENDING";
export const PENDING_PARSING_COMPLETION:string = "PENDING_PARSING_COMPLETION";
export const PENDING_TAX_CLASSIFICATION:string = "PENDING_TAX_CLASSIFICATION";
export const DUPLICATE_REJECTED:string = "DUPLICATE_REJECTED";
export const CLOSED_CASES:string = "CLOSED";
export const CLOSED_OTHER_CASES:string = "CLOSED_OTHER";
export const UNDER_PROCESSING_CASES:string = "UNDER_PROCESSING";
export const PENDING_USER_CLASSIFICATION = "Pending Invoice Analysis";
export const PENDING_WITHHOLDING_ASSESSMENT = "Pending Withholding Assessment";

export const SUCCESS = "success";
export const PENDING_PARSING_COMPLETION_LIST: string[] = [UNDER_PROCESSING_CASES, PENDING_PARSING_COMPLETION];
export const PENDING_CASES_LIST: string[] = [PENDING_CASES, PENDING_TAX_CLASSIFICATION, PENDING_PARSING_COMPLETION];
export const PENDING_TAX_CLASSIFICATION_DISABLED_BUTTON_LIST: string[] = [PENDING_CASES, PENDING_PARSING_COMPLETION];
export const PENDING_PARSING_DISABLED_BUTTON_LIST: string[] = [PENDING_CASES, PENDING_TAX_CLASSIFICATION];

export const CASES_DATE_RANGE_MAP = {
    "greenCasesDateRange": {
        "startDate": getFormattedDayStartDate(new Date()),
        "endDate": getFormattedDayEndDate(new Date())
    },
    "yellowCasesDateRange": {
        "startDate": getFormattedDayStartDate(new Date(
            new Date().setDate(new Date().getDate() - STRYFE_YELLOW_CASES_START_DATE_OFFSET))),
        "endDate": getFormattedDayEndDate(new Date(
            new Date().setDate(new Date().getDate() - STRYFE_YELLOW_CASES_END_DATE_OFFSET)))
    },
    "redCasesDateRange": {
        "startDate":DEFAULT_STRYFE_CASE_START_DATE,
        "endDate": getFormattedDayEndDate(new Date(
            new Date().setDate(new Date().getDate() - STRYFE_RED_CASES_END_DATE_OFFSET))),
    },
    "completedCasesDateRange": {
        "startDate": DEFAULT_STRYFE_CASE_START_DATE,
        "endDate": getFormattedDayEndDate(new Date())
    },
    "duplicateCasesDateRange": {
        "startDate": DEFAULT_STRYFE_CASE_START_DATE,
        "endDate": getFormattedDayEndDate(new Date())
    }
}

export const STRYFE_INTERNAL_ERROR_FLASH_BAR_PROPS = {
    header: "Senna could not load this time. Please reload the page.",
    type: "error",
    content: (<div>{"If this issue persists, kindly contact "}
        {getOnCallContactLink()}
    </div>),
};
