import React from "react";
import "./Card.scss"
import CardHeader from "src/components/stryfe/CardHeader";
import SLAWidget from "src/components/stryfe/Widgets/SLAWidget";
import CompletedCaseWidget from "src/components/stryfe/Widgets/CompletedCaseWidget";
import {CLOSED_CASES, UNDER_PROCESSING_CASES} from "src/components/StryfeAPI/Constants";
import UnderProcessingCaseWidget from "src/components/stryfe/Widgets/UnderProcessingWidget";

const Card = (props) => {

    let total;
    if (props.slaWidgetData == null) {
        total = null;
    } else {
        total = props.slaWidgetData.greenCasesSummary.count + props.slaWidgetData.yellowCasesSummary.count +
            props.slaWidgetData.redCasesSummary.count;
    }
    return (
        <div className="card">
            <div>
                <div className="header-border">
                    <CardHeader
                        title = {props.header.title}
                        subTitle = {props.header.subTitle}
                        aggregationNumber = {total}
                    >
                    </CardHeader>
                </div>
                <div>
                    <div className="widget-border">
                        {(props.caseStatus == CLOSED_CASES) ?
                            (
                                <div className="completed-case-widget">
                                <CompletedCaseWidget data={props}></CompletedCaseWidget>
                                </div>
                            ) :
                            (
                                (props.caseStatus == UNDER_PROCESSING_CASES) ?
                                (
                                    <div className="completed-case-widget">
                                    <UnderProcessingCaseWidget data={props}></UnderProcessingCaseWidget>
                                    </div>
                                ) :
                                (<SLAWidget data={props}></SLAWidget>)
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Card;