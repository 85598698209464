import React, {useState, useEffect} from "react";
import {Link, Flashbar, Box} from "@amzn/awsui-components-react";
import {utils,write} from 'xlsx';
import * as FileSaver from 'file-saver';
import {
    CLOSED_CASES, SUCCESS,
    PENDING_PARSING_COMPLETION,
    PENDING_PARSING_COMPLETION_LIST,
    PENDING_TAX_CLASSIFICATION,
    PENDING_USER_CLASSIFICATION,
    PENDING_WITHHOLDING_ASSESSMENT
} from "src/components/StryfeAPI/Constants";

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export function getOnCallContactLink(){
    return (<Link external
                 externalIconAriaLabel="Opens in a new tab"
                 href="https://oncall.corp.amazon.com/#/view/taskless_transformers/schedule">
        transformers
    </Link>
    );
}

export function getFlashBar({header, content, type}){
    const flashBarProps =
        {
            header: header,
            type: type,
            content: content,
            dismissible: false,
            id: "flashBar"
        }
    ;
    return <Flashbar items={[flashBarProps]}/>
}

export function getDate(offset)
{
    const today: Date = new Date();
    const year: number = today.getFullYear();
    const month: number = today.getMonth()+offset;
    const day: number = today.getDate();
    const formattedDate: string = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    return formattedDate;
}
export let ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height:4,
            width:"fit-content"
        }}
    />
);
export const ValueWithLabel = ({label, children}) => (
    <div>
        <Box variant="awsui-key-label">{label}</Box>
        <div>{children}</div>
    </div>
);

export function getFormattedDayStartDate(date: Date) {
    return formatDate(date) + ".00:00:00";
}

export function getFormattedDayEndDate(date: Date) {
    return formatDate(date) + ".23:59:59";
}
export function formatDate(date:Date){
    const today: Date = date;
    const year: number = today.getFullYear();
    const month: number = today.getMonth()+1;
    const day: number = today.getDate();
    const formattedDate: string = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    return formattedDate;
}

export  const exportToExcel = (data,name:string) => {
    const workSheet = utils.json_to_sheet(data);
    const workbook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] };
    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
    const file = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(file, name + fileExtension);
}

export const convertCamelCaseToEnglishCase = (camelCaseString: string) => {
    let englishCaseString = camelCaseString.replace(/([A-Z])/g, ' $1');
    const words = englishCaseString.toLowerCase().trim().split(' ');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
}

export const convertENtoBRNotation = (input: string) => {
    const number = parseFloat(input);
    // Format in Brazilian notation with 2 decimal places
    const formatter = new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return formatter.format(number);
}

export function areDatesOfSameMonth(stringDate1 : string, stringDate2 : string){
    const date1 = new Date(stringDate1);
    const date2 = new Date(convertStringDateFromDDMMtoMMDD(stringDate2));
    return date1.getUTCMonth() == date2.getUTCMonth() && date1.getUTCFullYear() == date2.getUTCFullYear();
}

export function isInvoiceReceivedAfterCutOffDate(caseCreationDateString : string, invoiceDateString : string){
    const compareDateResult = areDatesOfSameMonth(caseCreationDateString, invoiceDateString);
    if(!compareDateResult) {
        console.log("ReceivedOnTime is false");
        return false;
    }

    const caseCreationDate = new Date(caseCreationDateString);
    // Month of December
    if(caseCreationDate.getUTCMonth() == 11){
        if(caseCreationDate.getUTCDate() > 15) {
            console.log("Month is December and Date is greater than 15");
            return true;
        }
    }
    if(caseCreationDate.getUTCDate() > 20) {
        console.log("Date is greater than 20");
        return true;
    }
    return false;
}
export function convertStringDateFromDDMMtoMMDD(ddmmyyyyStringDate : string){
    const [dd, mm, yyyy] = ddmmyyyyStringDate.split('/');
    return new Date(mm + '/' + dd + '/' + yyyy);
}

export function isAttributeValueComputationRequired(caseStatus : string, attributeList : string[], flagsList : boolean[]){
    return !PENDING_PARSING_COMPLETION_LIST.includes(caseStatus)
        && attributeList.every(attribute => attribute != null)
        && flagsList.every(flag => flag == true);
}

export function getSennaStatus(caseStatus : String) {
    switch (caseStatus) {
        case PENDING_PARSING_COMPLETION:
            return PENDING_USER_CLASSIFICATION;
        case PENDING_TAX_CLASSIFICATION:
            return PENDING_WITHHOLDING_ASSESSMENT;
        case CLOSED_CASES:
            return SUCCESS;
        default:
            return "In Progress";
    }
}

export function getSennaStatusTypeIndicator(caseStatus : String) {
    switch (caseStatus) {
        case CLOSED_CASES :
            return SUCCESS;
        default :
            return "pending"
    }
}

