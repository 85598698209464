import { KatalComponent, register } from '../../shared/base';

/**
 * @component {kat-divider} KatalDivider A divider is a horizontal line that visually separates content on a page or within boxes, accordions, and other containers.
 * @guideline Dont Do not use dividers inside a paragraph.
 * @example athens {"variant": "athens"}
 * @example zircon {"variant": "zircon"}
 * @example nordic {"variant": "nordic"}
 * @example eastern {"variant": "eastern"}
 * @status Production
 * @theme flo
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-divider')
export class KatDivider extends KatalComponent {
  static get observedAttributes() {
    return ['variant'];
  }

  constructor() {
    super(KatDivider.observedAttributes);
  }

  /**
   * @classprop {enum} variant The type of divider to use
   * @enum {value} athens Small divider with the athens color
   * @enum {value} zircon Large divider with the zircon color
   * @enum {value} nordic Small divider with the nordic color
   * @enum {value} eastern Small divider with the eastern color
   */
  get variant() {
    return this.getAttribute('variant') || 'athens';
  }

  set variant(value) {
    this.setAttribute('variant', value);
  }
}
