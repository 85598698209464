import React, { useState } from "react";
import { API } from "aws-amplify";
import { getInputContext } from "../utils/api-utils";
import { CASE_MANAGEMENT_SERVICE_API } from "../constants/api-paths";

export async function getDownloadURLForS3Object(objectKey) {
    let stryfeRequest = {
        objectKey:objectKey,
    }
    const inputContext = await getInputContext();
    inputContext.body.requestType = "GetDownloadURLOfS3Object";
    inputContext.body.requestString = JSON.stringify(stryfeRequest);
    const resp = await API.post(
        CASE_MANAGEMENT_SERVICE_API,
        "/getdownloadurlofs3object",
        inputContext
    );
    if (resp.statusCode === "200") {
        const parsedjson = JSON.parse(resp.body);
        return parsedjson.downloadUrl;
    } else {
        console.log("No status code" + JSON.stringify(resp));
    }
}
