import React, { useState, useEffect } from "react";
import {
  Header,
  Table,
  BreadcrumbGroup,
  ColumnLayout,
  Box,
  Container,
  SpaceBetween,
  StatusIndicator,
  Link,
  Button,
  FormField,
  Select,
  Input,
  Textarea,
  Modal,
  Alert,
} from "@amzn/awsui-components-react";
import { SelectProps } from "@amzn/awsui-components-react/polaris/select";
import { updateCaseStatus } from "../../api/updateCaseStatus";
import { addCommentToCase } from "../../api/addCommentToCase";
import {
  SELECTION_LABELS,
} from "../../config/split-panel-config";
let commentValue = "";
export const Comment = (item) => {
  const [value, setValue] = useState("");
  return (
    <Textarea
      onChange={({ detail }) => {
                    setValue(detail.value);
                    commentValue = detail.value;
              }}
      value={value}
      placeholder="Add your comment here"
    />
  );
};

const Triage = (item) => {
  const defaultOption = [{ label: "Tax Levy", value: "1" }];
  const [selectedOption, setSelectedOption] = useState<SelectProps.Option>(defaultOption[0]);
  const defaultTTOption = [{ label: "Compliance360", value: "1" }];
  const [selectedTTOption, setSelectedTTOption] = useState<SelectProps.Option>(defaultTTOption[0]);
  const [shouldModalVisible, setModalVisibility] = React.useState(false);
  const [shouldTTModalVisible, setTTModalVisibility] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [showStatusAlert, setShowStatusAlert] = React.useState(false);
  let columnDefinitions :any[] = [];
  let commentChainJsonString = "";
  let commentSplit : string[] = [];
  let result : any = {};

 if(item.commentChain !== undefined) {
  commentChainJsonString = JSON.parse(item.commentChain) + '';

  console.log("Printing comment json - " + commentChainJsonString);

  const comments = commentChainJsonString.split(",");
  console.log("Printing comment - " + JSON.stringify(comments));

   result = comments.map((comment) => ({
    date: comment.split("- ",3)[1],
    user: comment.split("- ",3)[0],
    commentId: comment.split("- ",3)[2]
  }));

   columnDefinitions = [
    { id: "date", header: "Date", cell: (comment) => comment['date'] },
    {
      id: "user",
      header: "User",
      cell: (comment) => comment['user'],
    },
    { id: "commentId", header: "Comment", cell: (comment) => comment['commentId'] }
  ];
}

  const getTicketLink = (team) => {
  console.log("team = " + JSON.stringify(team));
    let category = "";
    let type = "";
    let ttitem = "";
    let group = "";

    switch (team.label) {
      case "Compliance360":
        category = "TAX";
        type = "Services";
        ttitem = "Compliance360";
        group = "Transformers";

      case "DCE":
        category = "TaxOps";
        type = "FAS5";
        ttitem = "Identity";
        group = "Taskless-Identity";
      case "PTL":
        category = "TaxOps";
        type = "Other-Compliance";
        ttitem = "Compliance and Controllership";
        group = "Taskless-Compliance-A-C";
      case "Reporting Engineering":
        category = "TAX";
        type = "Services";
        ttitem = "Reporting";
        group = "Avengers";
      default:
        category = "TAX";
        type = "Services";
        ttitem = "Compliance360";
        group = "Transformers";
    }

    const ticketLink =
      "https://t.corp.amazon.com/create/options?category=" +
      category +
      "&type=" +
      type +
      "&item=" +
      ttitem +
      "&group=" +
      group +
      "&severity=FIVE&title=ComplianceDashboard%20Cases%20Pending%20Action&description=This%20ticket%20is%20for%20tracking%20action%20pending%20on%20the%20following%20cases:%20%0D" +
      item["form id"];
    return ticketLink;
  };

  const showModal = () => {
    setModalVisibility(true);
  };

  const onModalCancel = () => {
    setModalVisibility(false);
  };

  const onModalConfirm = () => {
  console.log("selected option = " + JSON.stringify(selectedOption));
    const response = updateCaseStatus(
      item["caseId_partitionKey"],
      item["caseId_partitionKey"] +"#" + item["form id"],
      "FALSE-POSITIVE",
      "",
      selectedOption.label
    );
    setModalVisibility(false);
    setShowStatusAlert(true);
  };

  const showTTModal = () => {
    setTTModalVisibility(true);
  };

  const onTTModalCancel = () => {
    setTTModalVisibility(false);
  };

  const onTTModalConfirm = () => {
    setTTModalVisibility(false);
  };

const saveItems = async (item, comment) => {
  console.log("Saving item = " + JSON.stringify(comment));
  const response = addCommentToCase(
    item["caseId_partitionKey"],
    item["caseId_partitionKey"] + "#" + item["form id"],
    comment
  );
  //item.commentChain = item.commentChain + "," + comment;
  console.log("comment chain = " + JSON.stringify(item.commentChain));
  setShowAlert(true);
};

const PreviousComment = () => {
if(item.commentChain !== undefined) {
return (
<Container>
          <Table
            ariaLabels={SELECTION_LABELS}
            header={<Header variant="h2">Previous comments</Header>}
            items={result}
            columnDefinitions={columnDefinitions}
            variant="embedded"
          />
</Container>
);
}
else
return (<br/>);
};

  return (
  <div>
    <Container
      header={
        <Header
          variant="h3"
          actions={
            <SpaceBetween size="xs" direction="horizontal">
              <Button disabled = {item.Status==="FALSE-POSITIVE"} variant="primary" iconAlign="right" onClick={showModal}>
                Mark as False-Positive
              </Button>
              <Modal
                onDismiss={onModalCancel}
                visible={shouldModalVisible}
                closeAriaLabel="Close modal"
                footer={
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button variant="link" onClick={onModalCancel}>
                        Cancel
                      </Button>
                      <Button variant="primary" onClick={onModalConfirm}>
                        Ok
                      </Button>
                    </SpaceBetween>
                  </Box>
                }
                header="Mark case as false positive"
              >
                <SpaceBetween direction="vertical" size="l">
                  <div>
                    <b> Select reason </b>
                  </div>
                  <Select
                    selectedOption={selectedOption}
                    onChange={({ detail }) =>
                      setSelectedOption(detail.selectedOption)
                    }
                    options={[
                      { label: "Tax Levy", value: "1" },
                      {
                        label: "B-notice with payment-level analysis",
                        value: "2",
                      },
                      { label: "IMY with sufficient forms", value: "3" },
                      { label: "Government entities", value: "4" },
                      { label: "Legal settlement", value: "5" },
                      { label: "Trust/Estate", value: "6" },
                    ]}
                    selectedAriaLabel="Selected"
                  />
                </SpaceBetween>
              </Modal>
              <Button variant="primary" iconAlign="right" onClick={showTTModal}>
                Create a TT
              </Button>

              <Modal
                onDismiss={onTTModalCancel}
                visible={shouldTTModalVisible}
                closeAriaLabel="Close modal"
                footer={
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button variant="link" onClick={onTTModalCancel}>
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        href={getTicketLink(selectedTTOption)}
                        target="_blank"
                        onClick={onTTModalConfirm}
                      >
                        Ok
                      </Button>
                    </SpaceBetween>
                  </Box>
                }
                header="Create a Ticket"
              >
                <SpaceBetween direction="vertical" size="l">
                  <div>
                    <b> Select team </b>
                  </div>
                  <Select
                    selectedOption={selectedTTOption}
                    onChange={({ detail }) =>
                      setSelectedTTOption(detail.selectedOption)
                    }
                    options={[
                      { label: "Compliance360", value: "1" },
                      { label: "DCE", value: "2" },
                      { label: "PTL", value: "3" },
                      { label: "Reporting Engineering", value: "4" },
                    ]}
                    selectedAriaLabel="Selected"
                  />
                </SpaceBetween>
              </Modal>

              <Button variant="primary" iconAlign="right" onClick={() => saveItems(item, commentValue)}>
                Save
              </Button>
            </SpaceBetween>
          }
        >
          Triage
        </Header>
      }
    >
      <SpaceBetween size="m">
        <Box variant="strong">Comment</Box>
        <div>
          <Comment {...item} />
        </div>
      </SpaceBetween>
      <SpaceBetween size="m">
          <PreviousComment />
      </SpaceBetween>
          </Container>
                 <Alert
                        onDismiss={() => setShowAlert(false)}
                        visible={showAlert}
                        dismissAriaLabel="Close alert"
                        dismissible
                      >
                        Added comment. Please refresh to view latest comments.
                      </Alert>
                 <Alert
                        onDismiss={() => setShowStatusAlert(false)}
                        visible={showStatusAlert}
                        dismissAriaLabel="Close alert"
                        dismissible
                      >
                        Case marked as False-Positive. Please refresh to view latest status.
                      </Alert>
</div>
  );
};

export default Triage;
