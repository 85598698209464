import React, { useState } from "react";
import { API } from "aws-amplify";
import { getInputContext } from "../utils/api-utils";
import { CASE_MANAGEMENT_SERVICE_API } from "../constants/api-paths";
import {
    PENDING_CASES,
    STRYFE_GET_CASE_BY_STATUS_CLIENTID,
    STRYFE_USE_CASE_NAME
} from "src/components/StryfeAPI/Constants";


let result = {
    data: {},
    lastEvaluatedKey: null,
};

export async function getStryfeCasesByStatusAndDateRange(lastEvaluatedKey, startDate, endDate, caseStatus) {
    let stryfeRequest = {
        clientId: STRYFE_GET_CASE_BY_STATUS_CLIENTID,
        useCaseName: STRYFE_USE_CASE_NAME,
        startDate:startDate,
        status: caseStatus,
        endDate:endDate,
        team:"CAC",
        paginationToken: lastEvaluatedKey
    }
    const inputContext = await getInputContext();
    inputContext.body.requestType = "GetCasesByStatusAndOwnership";
    inputContext.body.requestString = JSON.stringify(stryfeRequest);
    const resp = await API.post(
        CASE_MANAGEMENT_SERVICE_API,
        "/getcasesbystatusandownership",
        inputContext
    );
    if (resp.statusCode === "200") {
        const parsedjson = JSON.parse(resp.body);
        result.data = parsedjson.matchedCases;
        result.lastEvaluatedKey = parsedjson.paginationToken;
        return result;
    } else {
        console.log("No status code" + JSON.stringify(resp));
    }
}
