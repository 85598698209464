import React, { useState, useEffect, useReducer } from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
} from "../../config/table/ads-fas5-config";
import {
  Preferences,
  PROPERTY_FILTERING_I18N_CONSTANTS
} from "../../config/table/common/case-table-config";
import {
  Pagination,
  Table,
  TextFilter,
  PropertyFilter,
  SplitPanel,
} from "@amzn/awsui-components-react";
import {
  Breadcrumbs,
  FullPageHeader,
  ToolsContent,
  TableEmptyState,
  TableNoMatchState
} from "../table/table-common-components";
import { getPanelContent, useSplitPanel } from "../table/split-panel-utils";
import { SPLIT_PANEL_I18NSTRINGS } from "../../config/split-panel-config";

import {
  CustomAppLayout
} from "../../common/common-components";
import {
  paginationLabels,
  caseSelectionLabels,
} from "../../common/labels";
import { getFilterCounterText } from "../table/table-counter-strings";
import "../../base.scss";
import { useColumnWidths } from "../table/use-column-widths";
import { useLocalStorage } from "../table/local-storage";
import {getCasesByStatusAndOwnership} from "../../api/getCasesByStatusAndOwnership";
import {tableReducer,INITIAL_STATE, tableReducerInit} from "../table/CaseDetailsReducer";

const Cases = (params) => {

const [openCases, setOpenCases]:any[] = useState([]);
const [evalKey, setEvalKey] = useState(null);
const [table, tableDispatcher] = useReducer(tableReducer,
        INITIAL_STATE, tableReducerInit);
const [isIntialised, setIsInitialised] = useState(true);
const [isLoading, setIsLoading] = useState(true);

let shouldCall = false;

useEffect(() => {

console.log("Use effect is called");
let returnedCases:any = {};

const fetchData = async() => {
shouldCall = evalKey!== null;
console.log("Open cases = " + JSON.stringify(openCases));
if(isIntialised || evalKey!== null)  {

   returnedCases = await getCasesByStatusAndOwnership(params.formType, evalKey);
    console.log("Fetched data = " + JSON.stringify(returnedCases));
    console.log("length - " + returnedCases.data.length);
    //openCases.concat(returnedCases.data);
  setOpenCases([...openCases, ...returnedCases.data]);
    setIsInitialised(false);
setIsLoading(false);
  if(returnedCases.lastEvaluatedKey !== null) {
  console.log("Last eval in useEffect " + JSON.stringify(returnedCases.lastEvaluatedKey));
    setEvalKey(returnedCases.lastEvaluatedKey);
  }
  console.log("eval key in use effect = " + JSON.stringify(evalKey));
}
};
fetchData();

},[evalKey]);

  const [toolsOpen, setToolsOpen] = useState(false);
  const [query, setQuery] = useState({
    tokens: [],
    operation: "and",
  });
  const [columnDefinitions, saveWidths] = useColumnWidths(
    "React-Table-Widths",
    COLUMN_DEFINITIONS
  );
  const [preferences, setPreferences] = useLocalStorage(
    "React-Table-Preferences",
    DEFAULT_PREFERENCES
  );
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(openCases, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: <TableEmptyState resourceName="AnomalyCases" />,
      noMatch: (
        <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
    selection: {},
  });
  const { header: panelHeader, body: panelBody } = getPanelContent(
    collectionProps.selectedItems,
    "multiple"
  );
  const {
    splitPanelOpen,
    onSplitPanelToggle,
    splitPanelSize,
    onSplitPanelResize,
    splitPanelPreferences,
  } = useSplitPanel(collectionProps.selectedItems);

  return (
    <CustomAppLayout
      breadcrumbs={<Breadcrumbs />}
      splitPanelOpen={splitPanelOpen}
      onSplitPanelToggle={onSplitPanelToggle}
      splitPanelSize={splitPanelSize}
      onSplitPanelResize={onSplitPanelResize}
      splitPanelPreferences={splitPanelPreferences}
      splitPanel={
        <SplitPanel
          header={panelHeader}
          i18nStrings={SPLIT_PANEL_I18NSTRINGS}
          hidePreferencesButton={true}
        >
          {panelBody}
        </SplitPanel>
      }
      content={
        <Table
          {...collectionProps}
          loading={isLoading}
          loadingText={table.loadingText}
          columnDefinitions={columnDefinitions}
          visibleColumns={preferences.visibleContent}
          items={items}
          selectionType="multi"
          ariaLabels={caseSelectionLabels}
          variant="full-page"
          stickyHeader={true}
          resizableColumns={true}
          onColumnWidthsChange={saveWidths}
          wrapLines={preferences.wrapLines}
          header={
            <FullPageHeader
              selectedItems={collectionProps.selectedItems}
              totalItems={openCases}
              updateTools={() => setToolsOpen(true)}
            />
          }
          filter={
            <PropertyFilter
              i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
              {...propertyFilterProps}
              countText={getFilterCounterText(filteredItemsCount)}
              expandToViewport={true}
            />
          }
          pagination={
            <Pagination {...paginationProps} ariaLabels={paginationLabels} />
          }
          preferences={
            <Preferences
              preferences={preferences}
              setPreferences={setPreferences}
            />
          }
        />
      }
      contentType="table"
      tools={<ToolsContent />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
    />
  );
};

export default Cases;