import React from "react";
import {
  BreadcrumbGroup,
  Button,
  HelpPanel,
  Icon,
  SpaceBetween,
  Alert,
  Box,
  Header,
} from "@amzn/awsui-components-react";
import { openCases } from "../../common/breadcrumbs";
import { ExternalLinkItem, InfoLink } from "../../common/common-components";
import {
  getHeaderCounterText,
  getServerHeaderCounterText,
} from "./table-counter-strings";

export const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={openCases}
    expandAriaLabel="Show path"
    ariaLabel="Breadcrumbs"
  />
);

export const FullPageHeader = ({
  resourceName = "Open Cases",
  refreshButtonText = "Refresh",
  ...props
}) => {
  const isOnlyOneSelected = props.selectedItems.length === 1;

  return (
    <TableHeader variant="awsui-h1-sticky" title={resourceName} {...props} />
  );
};

export const TableNoMatchState = (props) => (
  <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
    </SpaceBetween>
  </Box>
);

export const TableEmptyState = ({ resourceName }) => (
  <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No {resourceName.toLowerCase()}</b>
        <Box variant="p" color="inherit">
          No {resourceName.toLowerCase()} found for this filter.
        </Box>
      </div>
    </SpaceBetween>
  </Box>
);

function getCounter(props) {
  if (props.counter) {
    return props.counter;
  }
  if (!props.totalItems) {
    return null;
  }
  if (props.serverSide) {
    return getServerHeaderCounterText(props.totalItems, props.selectedItems);
  }
  return getHeaderCounterText(props.totalItems, props.selectedItems);
}

export const TableHeader = (props) => {
  return (
    <Header
      variant={props.variant}
      counter={getCounter(props)}
      info={
        props.updateTools && (
          <InfoLink id="infoLinkId" onFollow={props.updateTools} />
        )
      }
      description={props.description}
      actions={props.actionButtons}
    >
      {props.title}
    </Header>
  );
};

export const ToolsContent = () => (
  <HelpPanel header={<h2>Anomaly Cases</h2>}>
    <p> View Anomaly cases details </p>
  </HelpPanel>
);
