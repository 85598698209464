export const ROUTE_PATH_HOME_PAGE = "/";
export const ROUTE_PATH_NOT_FOUND_PAGE = "/not-found";
export const ROUTE_DEFAULT = "*";
export const ROUTE_SUMMARY = "/summary";
export const ROUTE_CASES = "/summary/cases";
export const ROUTE_CASES_UNDERWITHHOLDING = "/summary/cases/underwithholding";
export const ROUTE_CASES_1099_UNDERWITHHOLDING = "/summary/cases/1099/underwithholding";
export const ROUTE_CASES_1042S_UNDERWITHHOLDING = "/summary/cases/1042S/underwithholding";
export const ROUTE_STRYFE_ACTION_CENTRE = "/stryfeactioncentre";

