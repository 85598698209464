import React from "react";
import {getOnCallContactLink} from "../utils/common-utils";

export const SUMMARY_DASHBOARD = "SummaryDashboard";

export const NOT_AUTHORIZED_FLASH_BAR_PROPS = {
    header: "User is not authorized to access this page",
    type: "error",
    content: (<div>{"In-case this is an error, kindly contact "}
        {getOnCallContactLink()}
    </div>),
};

export const INTERNAL_ERROR_FLASH_BAR_PROPS = {
    header: "Some internal error occurred. Please try again later",
    type: "error",
    content: (<div>{"If this issue persists, kindly contact "}
        {getOnCallContactLink()}
    </div>),
};

export const DEFAULT_STRYFE_CASE_START_DATE = "2021-01-01.00:00:00";
export const STRYFE_YELLOW_CASES_START_DATE_OFFSET = 3;
export const STRYFE_YELLOW_CASES_END_DATE_OFFSET = 1;
export const STRYFE_RED_CASES_END_DATE_OFFSET = 4;


export const caseID = "content-Index1_secondaryFilter";