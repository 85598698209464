import {getCasesByStatusAndOwnership} from "../../api/getCasesByStatusAndOwnership";
import orderBy from 'lodash/orderBy';

export const INITIAL_STATE = {
    lastEvaluatedKey: null,
    cachedData:new Map(),
    pageCount:2,
    currentPageIndex:1,
    loading:true,
    loadingText:"Loading cases",
    initialize:true
};

export const tableReducerInit = initialState =>{
    return initialState;
}

export const tableReducer = (state, action) => {
    switch (action.type){
        case "API_RESPONSE":

            state.cachedData.set(state.currentPageIndex,action.payload.data);
            //console.log("redux action = " + JSON.stringify(state));
            return {
                ...state,
                loading: false,
                initialize:false,
                lastEvaluatedKey: action.payload.lastEvaluatedKey,
            }

        default:
            break;
    }
}
