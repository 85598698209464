import React, {useEffect, useState} from "react";
import {Container, Spinner} from "@amzn/awsui-components-react";
import {CustomAppLayout} from "src/common/common-components";
import "./StryfeDashboard.scss"
import StryfeInvoiceTable from "src/components/stryfe/stryfe-table-invoice";
import InvoiceDetails from "src/components/stryfe/InvoiceDetails";
import {getStryfeCasesSummary} from "src/api/getStryfeCasesSummary";
import {getDate, getFlashBar} from "src/utils/common-utils";
import Card from "src/components/stryfe/Card";
import "./StryfeHeader.scss"
import StryfeHeader from "src/components/stryfe/StryfeHeader";
import {
    CASES_DATE_RANGE_MAP,
    CLOSED_CASES,
    PENDING_PARSING_COMPLETION,
    PENDING_TAX_CLASSIFICATION,
    UNDER_PROCESSING_CASES,
    CLOSED_OTHER_CASES,
    DUPLICATE_REJECTED, STRYFE_INTERNAL_ERROR_FLASH_BAR_PROPS
} from "src/components/StryfeAPI/Constants";

import {
    STRYFE_TABLE_COLUMN_DEFINITIONS,
    STRYFE_DUPLICATE_TABLE_COLUMN_DEFINITIONS,
    STRYFE_VISIBLE_CONTENT_FOR_DUPLICATE_OPTIONS,
    STRYFE_VISIBLE_CONTENT_OPTIONS,
    STRYFE_DUPLICATE_FILTERING_PROPERTIES,
    STRYFE_FILTERING_PROPERTIES,
    STRYFE_TABLE_DEFAULT_DUPLICATE_PREFERENCES,
    STRYFE_TABLE_DEFAULT_PREFERENCES
} from "src/config/table/stryfe-config";

const StryfeDashboard = () => {

    const [isInvoicesVisible, setIsInvoicesVisible] = useState(false);
    const [isInvoiceDetailsVisible, setIsInvoiceDetailsVisible] = useState(false);
    const [isErrorFlashBarVisible, setIsErrorFlashBarVisible] = useState(false);
    const [isLoadSpinnerVisible, setIsLoadSpinnerVisible] = useState(true);
    const [invoice, setInvoice] = React.useState();
    const [startDate, setStartDate]: any = useState(getDate(-1) + ".00:00:00");
    const [endDate, setEndDate]: any = useState(getDate(1) + ".23:59:59");
    const [caseStatus, setCaseStatus]: any = useState(null);

    const callSetVisibleInvoiceDetails = (selectedItem) => {
        if (selectedItem !== null && status != DUPLICATE_REJECTED) {
            setIsInvoiceDetailsVisible(true);
            setInvoice(selectedItem);
        } else {
            setIsInvoiceDetailsVisible(false);
        }
    }

    const [slaWidget, setSlaWidget] = useState( {
        "pendingTaxClassificationCard": {
            "greenCasesSummary": {
                "heading": "On SLA",
                "count": 0,
            },
            "yellowCasesSummary": {
                "heading": "Close to SLA",
                "count": 0,
            },
            "redCasesSummary": {
                "heading": "Out of SLA",
                "count": 0,
            },
        },
        "pendingInvoiceParsingCard": {
            "greenCasesSummary": {
                "heading": "On SLA",
                "count": 0,
            },
            "yellowCasesSummary": {
                "heading": "Close to SLA",
                "count": 0,
            },
            "redCasesSummary": {
                "heading": "Out of SLA",
                "count": 0,
            },
        },
    });

    const cardHeader = {
        "pendingTaxClassificationCard": {
            "title": "Pending Withholding Assessment",
            "subTitle": "SOP could not be determined with sufficient confidence"
        },
        "pendingInvoiceParsingCard": {
            "title": "Pending Invoice Analysis",
            "subTitle": "Layouts not mapped on Dexter parser or missing mandatory fields or PDF not recognized"
        },
        "completedCasesCard": {
            "title": "Completed",
            "subTitle": "Cases with SOP determination completed"
        },
        "underProcessingCard": {
            "title": "Ingested to Senna",
            "subTitle": "All cases which have been submitted to Senna. No user action needed"
        }
    };

    const [completedWidget, setCompletedWidget] = useState( {
                "completedCasesSummary": {
                    "heading": "Total cases",
                    "count": 0,
                },
                "duplicateCasesSummary": {
                    "heading": "Duplicate cases",
                    "count": 0,
                },
                "nonServiceCasesSummary": {
                    "heading": "Goods cases",
                    "count": 0,
                }
    })

    const [underProcessingWidget, setUnderProcessingWidget] = useState( {
        "count": 0,
        "heading": "Total cases"
    })

    function getCaseItems(casesCountArray: any) {
        let dataItems = { PENDING_PARSING_COMPLETION: 0, PENDING_TAX_CLASSIFICATION: 0, CLOSED: 0, UNDER_PROCESSING: 0, DUPLICATE_REJECTED: 0, CLOSED_OTHER_CASES: 0};
        for(const dataItem of casesCountArray.data) {
            switch (dataItem.STATUS) {
                case PENDING_TAX_CLASSIFICATION :
                    dataItems.PENDING_TAX_CLASSIFICATION = Number(dataItem.COUNT);
                    break;
                case CLOSED_CASES :
                    dataItems.CLOSED = Number(dataItem.COUNT);
                    break;
                case DUPLICATE_REJECTED :
                    dataItems.DUPLICATE_REJECTED = Number(dataItem.COUNT);
                    break;
                case PENDING_PARSING_COMPLETION:
                    dataItems.PENDING_PARSING_COMPLETION = Number(dataItem.COUNT);
                    break;
                case UNDER_PROCESSING_CASES:
                    dataItems.UNDER_PROCESSING = Number(dataItem.COUNT);
                    break;
                case CLOSED_OTHER_CASES:
                    dataItems.CLOSED_OTHER_CASES = Number(dataItem.COUNT);
                    break;
            }
        }
        return dataItems;
    }

    async function createItems() {
        try {
            let greenCasesList = await getStryfeCasesSummary(CASES_DATE_RANGE_MAP.greenCasesDateRange.startDate,
                CASES_DATE_RANGE_MAP.greenCasesDateRange.endDate);
            let yellowCasesList = await getStryfeCasesSummary(CASES_DATE_RANGE_MAP.yellowCasesDateRange.startDate,
                CASES_DATE_RANGE_MAP.yellowCasesDateRange.endDate);
            let redCasesList = await getStryfeCasesSummary(CASES_DATE_RANGE_MAP.redCasesDateRange.startDate,
                CASES_DATE_RANGE_MAP.redCasesDateRange.endDate);

            const greenCases = getCaseItems(greenCasesList);
            const yellowCases = getCaseItems(yellowCasesList);
            const redCases = getCaseItems(redCasesList);

            setSlaWidget(prevState => ({
                pendingTaxClassificationCard: {
                    greenCasesSummary: {
                        ...prevState.pendingTaxClassificationCard.greenCasesSummary,
                        count: greenCases.PENDING_TAX_CLASSIFICATION,
                    },
                    yellowCasesSummary: {
                        ...prevState.pendingTaxClassificationCard.yellowCasesSummary,
                        count: yellowCases.PENDING_TAX_CLASSIFICATION,
                    },
                    redCasesSummary: {
                        ...prevState.pendingTaxClassificationCard.redCasesSummary,
                        count: redCases.PENDING_TAX_CLASSIFICATION,
                    },
                },
                pendingInvoiceParsingCard: {
                    greenCasesSummary: {
                        ...prevState.pendingInvoiceParsingCard.greenCasesSummary,
                        count: greenCases.PENDING_PARSING_COMPLETION,
                    },
                    yellowCasesSummary: {
                        ...prevState.pendingInvoiceParsingCard.yellowCasesSummary,
                        count: yellowCases.PENDING_PARSING_COMPLETION,
                    },
                    redCasesSummary: {
                        ...prevState.pendingInvoiceParsingCard.redCasesSummary,
                        count: redCases.PENDING_PARSING_COMPLETION,
                    },
                }
            }));

            setCompletedWidget(prevState => ({
                completedCasesSummary: {
                    ...prevState.completedCasesSummary,
                    count: greenCases.CLOSED + yellowCases.CLOSED + redCases.CLOSED,
                },
                duplicateCasesSummary: {
                    ...prevState.duplicateCasesSummary,
                    count: greenCases.DUPLICATE_REJECTED + yellowCases.DUPLICATE_REJECTED + redCases.DUPLICATE_REJECTED,
                },
                nonServiceCasesSummary: {
                    ...prevState.nonServiceCasesSummary,
                    count: greenCases.CLOSED_OTHER_CASES + yellowCases.CLOSED_OTHER_CASES + redCases.CLOSED_OTHER_CASES,
                },
            }));

            setUnderProcessingWidget(prevState => ({
                heading: "Under Processing Cases",
                count: greenCases.UNDER_PROCESSING + yellowCases.UNDER_PROCESSING + redCases.UNDER_PROCESSING
            }));
            setIsLoadSpinnerVisible(false);
        } catch (e) {
            setIsLoadSpinnerVisible(false);
            setIsErrorFlashBarVisible(true);
        }

    }

    useEffect(() => {
        createItems();
    }, []);

    function updateStartDate(date: Date) {
        setStartDate(date);
    }

    function updateEndDate(date: Date) {
        setEndDate(date);
    }

    function updateCaseStatus(caseStatus: String) {
        setCaseStatus(caseStatus);
    }

    return (
        <div>
        <StryfeHeader/>
        <CustomAppLayout
            content={
                <div style={{
                    marginTop:'4%'
                }}>
                    {isLoadSpinnerVisible ? (<Spinner size="large"></Spinner>) :
                        (isErrorFlashBarVisible ? getFlashBar(STRYFE_INTERNAL_ERROR_FLASH_BAR_PROPS):
                            <div className="cards-container">
                            <Card header={cardHeader.underProcessingCard}
                            underProcessingWidgetData={underProcessingWidget}
                            onClick={setIsInvoicesVisible}
                            updateStartDate={updateStartDate}
                            updateEndDate={updateEndDate}
                            updateCaseStatus={updateCaseStatus}
                            caseStatus={UNDER_PROCESSING_CASES}>
                            </Card>
                            <Card header={cardHeader.pendingInvoiceParsingCard}
                            slaWidgetData={slaWidget.pendingInvoiceParsingCard}
                            onClick={setIsInvoicesVisible} updateStartDate={updateStartDate}
                            updateEndDate={updateEndDate} updateCaseStatus={updateCaseStatus}
                            caseStatus={PENDING_PARSING_COMPLETION}>
                            </Card>
                            <Card header={cardHeader.pendingTaxClassificationCard}
                            slaWidgetData={slaWidget.pendingTaxClassificationCard}
                            onClick={setIsInvoicesVisible} updateStartDate={updateStartDate}
                            updateEndDate={updateEndDate} updateCaseStatus={updateCaseStatus}
                            caseStatus={PENDING_TAX_CLASSIFICATION}>
                            </Card>
                            <Card header={cardHeader.completedCasesCard}
                            completedCaseWidgetData={completedWidget}
                            onClick={setIsInvoicesVisible}
                            updateStartDate={updateStartDate}
                            updateEndDate={updateEndDate}
                            updateCaseStatus={updateCaseStatus}
                            caseStatus={CLOSED_CASES}>
                            </Card>
                            </div>
                        )
                    }
                    <br/>
                    {isInvoicesVisible &&
                        <Container header={<h2>Invoices</h2>}>
                            <StryfeInvoiceTable onClick={callSetVisibleInvoiceDetails}
                                                startDate={startDate}
                                                endDate={endDate}
                                                showSelectionBoxes={caseStatus == DUPLICATE_REJECTED ? false : true}
                                                columnDefinition={
                                                    (caseStatus==DUPLICATE_REJECTED
                                                                ?STRYFE_DUPLICATE_TABLE_COLUMN_DEFINITIONS
                                                                :STRYFE_TABLE_COLUMN_DEFINITIONS)
                                                }
                                                visibleContentOptions={
                                                    (caseStatus==DUPLICATE_REJECTED
                                                                ?STRYFE_VISIBLE_CONTENT_FOR_DUPLICATE_OPTIONS
                                                                :STRYFE_VISIBLE_CONTENT_OPTIONS)
                                                }
                                                filteringProperties={
                                                    (caseStatus==DUPLICATE_REJECTED
                                                                 ?STRYFE_DUPLICATE_FILTERING_PROPERTIES
                                                                 :STRYFE_FILTERING_PROPERTIES)
                                                }
                                                defaultPreferences ={
                                                    (caseStatus==DUPLICATE_REJECTED
                                                               ?STRYFE_TABLE_DEFAULT_DUPLICATE_PREFERENCES
                                                               :STRYFE_TABLE_DEFAULT_PREFERENCES)
                                                }
                                                caseStatus={caseStatus}/>
                        </Container>
                    }
                    <br/>
                    {
                        isInvoicesVisible && caseStatus!=DUPLICATE_REJECTED && isInvoiceDetailsVisible &&
                        <InvoiceDetails invoiceDetail={invoice} caseStatus={caseStatus}/>
                    }
                </div>
            }
        />
        </div>
    )
};

export default StryfeDashboard;

