import React, { useState, useEffect } from "react";
import {
  Header,
  Table,
  BreadcrumbGroup,
  ColumnLayout,
  Box,
  Container,
  SpaceBetween,
  StatusIndicator,
  Link,
  Button,
  FormField,
  Select,
  Input,
  Textarea
} from "@amzn/awsui-components-react";
import Modal from '@amzn/awsui-components-react/polaris/modal';
import { SelectProps } from "@amzn/awsui-components-react/polaris/select";
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar";
import {
  SELECTION_LABELS,
  EMPTY_PANEL_CONTENT
} from "../../config/split-panel-config";
import { CounterLink} from "../../common/common-components";
import { KatDivider } from "@amzn/katal-react";
import { addCommentToCase } from "../../api/addCommentToCase";
import Triage from "../splitPanel/Triage";
import TriageMultiple from "../splitPanel/TriageMultiple";

let commentValue = "";
let showCommentFlash = false;
let isModalVisible = false;
//const [commentValue, setCommentValue] = useState("");
export const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);
 // const [showCommentFlash, setShowCommentFlash] = useState(false);
    const saveItems = async (item, comment) => {
//       setLoading(true);
//       setFetchError(null);
      //try {
      console.log("Saving item = " + JSON.stringify(comment));
        const response = addCommentToCase(item['caseId_partitionKey'], item['caseId_partitionKey'], comment);
item.commentChain = item.commentChain+","+comment;
console.log("comment chain = " + JSON.stringify(item.commentChain));

showCommentFlash = true;
        //setAllItems(distributions as Distribution[]);
      //} catch (error: unknown) {
        //setFetchError(error instanceof Error ? error.message : String(error));
      //}
    };

// const ShowFlash = (comments) => {
// return (
// <Container>
//           <SpaceBetween size="m">
//            <ValueWithLabel label="Assignee's Comments">{comments}</ValueWithLabel>
//           </SpaceBetween>
//         </Container>
//         );
// }

export const getPanelContent = (items, type) => {
const [shouldModalVisible, setModalVisibility] = React.useState(false);
  if (type === "multiple") {
    return getPanelContentMultiple(items);
  } else {
    return getPanelContentSingle(items);
  }
};

export const Comment = (item) => {
  const [value, setValue] = useState("");
  return (
    <Textarea
      onChange={({ detail }) => {
                    setValue(detail.value);
                    commentValue = detail.value;
              }}
      value={value}
      placeholder="Add your comment here"
    />
  );
};

export const ShowModal = () => {
  //const [visible, setVisible] = React.useState(false);
  return (
    <Modal
      onDismiss={() => dismissModal()}
      visible={isModalVisible}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link">Cancel</Button>
            <Button variant="primary">Ok</Button>
          </SpaceBetween>
        </Box>
      }
      header="Mark case as false positive"
    >
      Your description should go here
    </Modal>
  );
};

function showModal(){
console.log("Showing modal");
isModalVisible = true;

console.log("flag valye = " + isModalVisible);
//e.preventDefault();
}

  function dismissModal() {
  console.log("dismiss modal");
  isModalVisible = false;
  console.log("flag value = " + isModalVisible);

  }

export const getPanelContentSingle = (items) => {

//  // const [shouldModalVisible, setModalVisibility] = React.useState(false);
//
//   const showModal = () => {
//       setModalVisibility(true);
//     };
//
//   const onModalCancel = () => {
//     setModalVisibility(false);
//   };
//
//   const onModalConfirm = () => {
//     setModalVisibility(false);
//   };

  if (!items.length) {
    return EMPTY_PANEL_CONTENT;
  }
  const item = items[0];
  const ticketLink = "https://t.corp.amazon.com/create/options?category=TAX&type=Services&item=Compliance360&group=Transformers&severity=FIVE&title=ComplianceDashboard%20Cases%20Pending%20Action&description=This%20ticket%20is%20for%20tracking%20action%20pending%20on%20the%20following%20cases:%20%0D" + item['form id'];
  return {
    header: "Form " + item['form id'],
    body: (
      <div>
        <Container header={<Header variant="h3">Under-Withholding Anomaly</Header>}>
          <SpaceBetween size="m">
            <Box variant="strong">Form Attributes</Box>
            <ValueWithLabel label= "Federal Withholding Amount (USD)" >
              {item['federal withholding amount']}
            </ValueWithLabel>
            <KatDivider variant="athens" />
            <ValueWithLabel label="Expected Withholding Amount (USD)">
              {item['expected withholding amount']}
            </ValueWithLabel>
          </SpaceBetween>
        </Container>

{/*         <Container */}
{/*           header={ */}
{/*             <Header */}
{/*               variant="h3" */}
{/*               actions={ */}
{/*                 <SpaceBetween size="xs" direction="horizontal"> */}
{/*                                 <Button variant="primary" iconAlign="right" onClick={showModal}> */}
{/*                                                     Mark as False-Positive */}
{/*                                                   </Button> */}
{/*                  <Modal */}
{/*                        onDismiss={onModalCancel} */}
{/*                        visible={shouldModalVisible} */}
{/*                        closeAriaLabel="Close modal" */}
{/*                        footer={ */}
{/*                          <Box float="right"> */}
{/*                            <SpaceBetween direction="horizontal" size="xs"> */}
{/*                              <Button variant="link" onClick={onModalCancel}>Cancel</Button> */}
{/*                              <Button variant="primary" onClick={onModalConfirm}>Ok</Button> */}
{/*                            </SpaceBetween> */}
{/*                          </Box> */}
{/*                        } */}
{/*                        header="Mark case as false positive" */}
{/*                      > */}
{/*                        Your description should go here */}
{/*                      </Modal> */}
{/*                   <Button */}
{/*                     href={ticketLink} */}
{/*                     iconName="external" */}
{/*                     target="_blank" */}
{/*                   > */}
{/*                     Create a TT */}
{/*                   </Button> */}
{/*                                     <Button variant="primary" iconAlign="right" onClick={() => saveItems(item, commentValue)}> */}
{/*                                       Save */}
{/*                                     </Button> */}
{/*                 </SpaceBetween> */}
{/*               } */}
{/*             > */}
{/*               Triage */}
{/*             </Header> */}
{/*           } */}
{/*         > */}
{/*           <SpaceBetween size="m"> */}
{/*             <Box variant="strong">Comment</Box> */}
{/*             <div> */}
{/*               <Comment {...item}/> */}
{/*             </div> */}
{/*           </SpaceBetween> */}
{/*         </Container> */}

<Triage {...item} />

        <Container
          header={
            <Header
              variant="h3"
              actions={
                <Button
                  href={
                    "https://tom-na-iad.iad.proxy.amazon.com/tom-lite/reporting/form-detail/" +
                    item['form id']
                  }
                  iconName="external"
                  target="_blank"
                >
                  View Form details
                </Button>
              }
            >
              Form Attributes
            </Header>
          }
        >
          <ColumnLayout columns={2}>
            <SpaceBetween size="l">
              <ValueWithLabel label="Form ID">{item['form id']}</ValueWithLabel>
              <ValueWithLabel label="Country">US</ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="Tax year">{item['tax year']}</ValueWithLabel>
              <ValueWithLabel label="Form Expiry date">
                {item['form expiry date']}
              </ValueWithLabel>
            </SpaceBetween>
          </ColumnLayout>
          <br />
          <KatDivider variant="athens" /> <br />
          <ColumnLayout columns={2}>
            <SpaceBetween size="l">
              <ValueWithLabel label="Tax Rate">
                {item['withholding rate']}
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <ValueWithLabel label="Gross Amount (USD)">
                {item['gross amount']}
              </ValueWithLabel>
            </SpaceBetween>
          </ColumnLayout>
        </Container>

        <Container
          header={
            <Header
              variant="h3"
              actions={
                <SpaceBetween size="xs" direction="horizontal">
                  <Button
                    href="https://ofa-test.fin.amazon.com/OA_HTML/OA.jsp?OAFunc=OANEWHOMEPAGE"
                    iconName="external"
                    target="_blank"
                    disabled
                  >
                    View in OFA
                  </Button>
                  <Button
                    href="https://finopscentral-na.amazon.com/"
                    iconName="external"
                    target="_blank"
                    disabled
                  >
                    View in Payee Central
                  </Button>
                  <Button
                    href={
                      "https://tom-na-iad.iad.proxy.amazon.com/tom-lite/detail/" +
                      item['identity key'] +
                      "/" +
                      item['client id'] +
                      "/" +
                      item['account id']
                    }
                    iconName="external"
                    target="_blank"
                  >
                    View in TOM
                  </Button>
                </SpaceBetween>
              }
            >
              Payee Details
            </Header>
          }
        >
          <ColumnLayout columns={2}>
            <SpaceBetween size="l">
              <ValueWithLabel label="Client ID">{item['client id']}</ValueWithLabel>
                            <ValueWithLabel label="Account ID">
                              {item['account id']}
                            </ValueWithLabel>

            </SpaceBetween>
            <SpaceBetween size="l">
<ValueWithLabel label="Identity Key">
                {item['identity key']}
              </ValueWithLabel>
              <ValueWithLabel label="TIMS Form Type">{item['tims form type']}</ValueWithLabel>
            </SpaceBetween>
          </ColumnLayout>
        </Container>
      </div>
    ),
  };
};

export const getPanelContentMultiple = (items) => {
  if (!items.length) {
    return EMPTY_PANEL_CONTENT;
  }

const itemsCopy = items;
console.log("shallow copy items  =" + JSON.stringify(itemsCopy));
  if (items.length === 1) {
    return getPanelContentSingle(items);
  }

  const keyHeaderMap = {
    formId: "Form ID",
    status: "Status",
  };
  const transformedData = ["formId", "status"].map((key) => {
    const data = { comparisonType: keyHeaderMap[key] };

    for (const item of items) {
      data[item.id] = item[key];
    }
    return data;
  });

  const result = items.map((item) => ({
    formId: item['form id'],
    commentChain: item['commentChain'],
  }));

  const columnDefinitions = [
    { id: "formId", header: "Form ID", cell: (item) => item['formId'] },
    {
      id: "commentChain",
      header: "Comments",
      cell: (item) => item['commentChain'],
    },
  ];

  const params = {
   data : items,
   length : items.length
  };
// let ticketLinkSuffix = "";
// for (const item of items) {
//     ticketLinkSuffix = ticketLinkSuffix + "%0D" + item['form id'];
// }
//
//   const ticketLink = "https://t.corp.amazon.com/create/options?category=TAX&type=Services&item=Compliance360&group=Transformers&severity=FIVE&title=ComplianceDashboard%20Cases%20Pending%20Action&description=This%20ticket%20is%20for%20tracking%20action%20pending%20on%20the%20following%20cases:%20" + ticketLinkSuffix;
    return {
    header: `${items.length} cases selected`,
    body: (
      <div>

              <TriageMultiple items={items} length={items.length} />

        <Box padding={{ bottom: "l" }}>
          <Table
            ariaLabels={SELECTION_LABELS}
            header={<Header variant="h2">Details of selected cases</Header>}
            items={result}
            columnDefinitions={columnDefinitions}
            variant="embedded"
          />
        </Box>
      </div>
    ),
  };
};

export const useSplitPanel = (selectedItems) => {
  const [splitPanelSize, setSplitPanelSize] = useState(650);
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);
  const [splitPanelPreferences, setSplitPanelPreferences] = useState({
    position: "side",
  });
  const [hasManuallyClosedOnce, setHasManuallyClosedOnce] = useState(false);

  const onSplitPanelResize = ({ detail: { size } }) => {
    setSplitPanelSize(size);
  };

  const onSplitPanelToggle = ({ detail: { open } }) => {
    setSplitPanelOpen(open);

    if (!open) {
      setHasManuallyClosedOnce(true);
    }
  };

  useEffect(() => {
    if (selectedItems.length && !hasManuallyClosedOnce) {
      setSplitPanelOpen(true);
    }
  }, [selectedItems.length, hasManuallyClosedOnce]);

  return {
    splitPanelOpen,
    onSplitPanelToggle,
    splitPanelSize,
    onSplitPanelResize,
    splitPanelPreferences,
  };
};

